.paragraph-list-image {
	margin-top: 20px;
	@media(min-width:break($_ml)){
		margin-top: 45px;
	}
}

.list-image__section-title {
	margin-bottom: 20px;
	@media(min-width:break($_ml)){
		margin-bottom: 45px;
	}
	.paragraph-list-image & {
		font-size: 1.6rem;
		font-weight: 700;
		text-transform: none;
		text-align: left;
		line-height: 1.3;
		letter-spacing: normal;
		display: inline-block;
		&:after {
			@extend .o-divider-line;
			content: '';
			position: relative;
			margin: 3px 0 15px;
			display: block;
			width: 100%;
		}
		@media screen and (min-width: break($_s)) {
	   	font-size: 1.6rem;
		}
	}
}