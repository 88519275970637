//Local Nav
.local-nav{
  height: $header-height;
  position:fixed;
	bottom: 0;
  z-index:500;
  padding:0 10px;
	visibility: hidden;
  border-top: 1px solid $c-brown-lt;

  -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  &.affix{
    opacity: 1;
    z-index: 2000;
	visibility: visible;
  }
  @media(min-width:break(600px)){
    height: $header-height--lg;
    top: 0;
  	left: 0;
    border-bottom: 1px solid $c-brown-lt;
    opacity: 0;
	}
}

.local-nav__inner {
	@media(max-width:break(599px)){
	justify-content: center;
}
}

.local-nav__title{
  line-height:$header-height;
  display: none;
   @media(min-width:break(600px)){
   	 line-height: $header-height--lg;
   	 flex: 0 1 auto;
   	 margin-right: 30px;
   	 min-width: 0;
   	 display: block;
   }
}

.local-nav__event-title {
 	white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.local-nav__buttons {
 	 flex: 0 1 auto;
	 @media(min-width:break(600px)){
	 	flex: 0 0 auto;
	 }
}

.local-nav__button{
	display: inline-block;
	width: auto;
	padding: .8em 1em;
	@media(min-width:break(600px)){
		margin:0 10px;

	}
	+ .local-nav__button {
		@media(max-width:break(599px)){
		margin-left: 10px;
		}
	}
}

.local-nav__scroll-top {
  @extend .local-nav__button;
  margin: 0;
  padding-right: 0;
	display: none;
	@media(min-width:break(600px)){
	 	display: inline-block;
	 }
}

.local-nav__scroll-icon {
	font-size: 6px;
	vertical-align: middle;
}