

.square-cta__item {
  float: left;
  position: relative;
  background-color: $c-white;
  &:before {
    content: "";
    display: block;
    @media(min-width:break($_ml)){
	    padding-top: 100%;
    }
	}

  .square-cta__item-content {
    position: relative;
    display: table;
    width: 100%;
    max-width: 80%;
		margin: 0 auto;
    height: 100%;
    .inner {
      display: table-cell;
      vertical-align: middle;
    }

    @media(max-width:break($_ml, false)){
      padding-bottom: 10px;
    }

	}

  .square-cta__item-img {
  	padding-top: 56.25%;
		position: relative;
		@media(min-width:break($_ml)){
      transition: all $trans-duration ease-in-out;
			position: absolute;
			// @extend .p-fill;
      @include p-fill;
			width: 100%;
			height: 100%;
      padding-top: 100%;
		}

  	&:before {
			content: ' ';
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    background: rgba(0,0,0, .2);
	    transition: background $trans-duration ease-in-out;
		}
  }

  .square-cta__item-title {
    transition: all $trans-duration ease-in-out;
    &.show-ml-min, &.show-xl-min {
      margin: auto;
    }
    &.hide-ml-min, &.hide-xl-min {
      max-width: 90%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .square-cta__item-text {
    user-select: text;
    position: relative;
    transition: max-height 450ms cubic-bezier(.72, 0, .28, 1);
    overflow: visible !important;

    p:not(.square-cta__item-link) {
      position: relative;
      padding-top: 1rem;
      transition: opacity 450ms cubic-bezier(.72, 0, .28, 1);
      @media(min-width:break($_ml)){
      	color: $c-white;
      }
    }
  }

  .square-cta__item-link {
      color: $c-blue;
      &:hover {
        color: $c-blue-dk;
      }
    @media(min-width:break($_ml)){
      color: $c-white;
        &:hover {
          color: $c-white;
      }
    }
  }

  > a {
    position: relative;
    display: block;
    overflow: hidden;
    @media(min-width:break($_ml)){
    	position: absolute;
    	// @extend .p-fill;
      @include p-fill;
    }

    &:hover {
      @media(min-width:break($_ml)){
        .square-cta__item-img {
          filter: blur(2px);
          transform: scale(1.01);
        }
        .square-cta__item-title {
          filter: none;
        }
      }
      .square-cta__item-img:before {
        background: rgba(0,0,0, .4);
      }
    }
  }
}