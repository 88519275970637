%hide {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

%show {
  visibility: visible;
  opacity: 1;
  height: auto;
}

%hero-title {
  font-size: 12px;
  line-height: 1.8;
  font-weight: 600;
  @media (min-width: break($_s)) {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 5px;
  }
}

%hero-description {
  font-size: 12px;
  line-height: 1.5;
  @media (min-width: break($_s)) {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.27px;
  }
}

@keyframes slick-prev {
  0%    { transform: translate(   0,  -50%) }
  50%   { transform: translate(-4px,  -50%) }
  100%  { transform: translate(   0,  -50%) }
}
@keyframes slick-next {
  0%    { transform: translate(   0,  -50%) }
  50%   { transform: translate( 4px,  -50%) }
  100%  { transform: translate(   0,  -50%) }
}

.hero-4-up__wrapper {
  @media (min-width: break($_m)) {
    display: flex;
    overflow: hidden;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    i {
      color: $c-white;
      font-size: 3rem;
    }
    &.slick-prev {
      left: 0;
      padding: 5% 5% 5% 7%;
      @media (max-width: break($_m)) {
        &:hover {
          animation: slick-prev 0.3s ease-in-out forwards alternate;
        }
      }
    }
    &.slick-next {
      right: 0;
      padding: 5% 7% 5% 5%;
      @media (max-width: break($_m)) {
        &:hover {
          animation: slick-next 0.3s ease-in-out forwards alternate;
        }
      }
    }
    &.slick-disabled {
      @extend %hide;
    }
  }
}

.hero-4-up__item {
  position: relative;
  flex: 0 0 percentage(4/9);
  max-height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  text-align: center;
  &:before {
    content: '';
    padding-top: percentage(240/140);
    display: block;
  }

  &:hover {
    .hero-4-up__inner {
      &:before {
        visibility: visible;
        opacity: 1;
      }
    }
    .hero-4-up__overlay {
      @extend %show;
      padding-bottom: 20px;
      transform: translateY(0);
    }
  }

  @media (min-width: break($_m)) {
    flex: 1 0 25%;
    &:before {
      padding-top: percentage(650/360);
    }
    &:hover {
      .hero-4-up__overlay {
        padding-bottom: 60px;
      }
    }
  }
}
.hero-4-up__inner {
  &:before,
  &:after {
    transition: all 0.3s ease-in-out;
  }

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.40);
    visibility: hidden;
    opacity: 0
  }

  &:after{
    content: '';
    background-image: linear-gradient(-180deg, transparent, rgba(0, 0, 0, 0.66) 98%);
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
.hero-4-up__block {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  color: $c-white;

  @media (max-width: break($_m)) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: break($_m)) {
    padding: 0 35px;
  }
}

.hero-4-up__overlay {
  @extend %hide;
  z-index: 2;
  transform: translateY(100%);
  transition: all 0.7s ease;
}

.hero-4-up__title {
  @extend %hero-title;
  margin-bottom: 20px;
  color: $c-white;
  z-index: 2;
  transition: all 0.5s ease;
  @media (min-width: break($_m)) {
    margin-bottom: 30px;
  }
}

.hero-4-up__description {
  margin: -20px 0 20px 0;
  transition: all 0.7s ease;
  p {
    @extend %hero-description;
  }
}
.hero-4-up__button {
  margin: 0 auto;
  max-width: none;
}
.hero-4-up__description, .hero-4-up__button {
  @media (max-width: break($_m)) {
    display: none;
  }
}
