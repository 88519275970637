.post--people__image {
	max-width: 300px;
	flex: 1 100%;
	image-rendering: -webkit-optimize-contrast;
	@media(min-width:break(600px)){
		flex: 1 45%;
	}
}

 .post--people__description {
		flex: 1 100%;
		margin-top: 20px;
		@media(min-width:break(600px)){
			flex: 0 55%;
			margin-top: 0;
			padding-left: 1.5em;
		}
 }