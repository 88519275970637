//Home Hero
.home-hero {
	+ section:not(.bg-c-cream) {
		margin-top: $section-spacing / 2;
	 	@media(min-width:break($_s)){
    	margin-top: $section-spacing--lg / 2;
  	}
	}
}

.home-hero__splash {
	background-size: cover;
	z-index: 3;
}

.home-hero__button-wrapper {
	@extend .hero__button-wrapper;
	position: relative;
	z-index: 2;
}

.home-hero__button {
	margin: 15px auto 0;
	
}

@media (min-width: break($_ml)) {
	//Home Hero
	.home-hero {
		position: relative;
		overflow: hidden;

		&.ready {
			.home-hero__splash {
				animation: ZoomSplashImage 2s ease-in forwards;
			}

			.home-hero__blue {
				animation: WipeBlueBar 2s 1.5s;
			}

			.home-hero__sidebar-content {
				opacity: 0;
				animation: FadeInSidebarText 1s 2.5s forwards;
			}

			&.done {
				.home-hero__splash {
					display: none;
				}
				.home-hero__blue {
					animation: none;
				}
			}
		}
	}

	.home-hero__splash {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		// opacity: 0;
		pointer-events: none;
	}

	.home-hero__button-wrapper,
	.home-hero__splash-title {
		display: none;
	}

	.home-hero__blue {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		background: $c-blue;
		opacity: 0;
		pointer-events: none;
	}

	.home-hero__sidebar {
		position: relative;
		float: left;
		width: 255px;
		padding-left: 30px;
		padding-right: 30px;
		z-index: 2;
		background: $c-blue;

		&, h3 {
			color: white;
		}
	}

	.home-hero__sidebar-content {
		position: relative;
	}

	.home-hero__sidebar-heading {
		margin-bottom: 140px;
	}
	.home-hero__sidebar-text {
      	p {
			margin-top: 1em;
		}
		img {
			margin-top: 5px;
			margin-bottom: 5px;
			width: 100%;
			height: auto;
		}
	}

	.home-hero__performance-list-item {
		position: relative;
		margin: 1em 0;
		a {
			display: block;
			position: relative;
			opacity: 0.4;
			transition: opacity 350ms;
		}

		&:before {
			content: '';
			position: absolute;
			left: -30px;
			top: 0;
			bottom: 0;
			width: 3px;
			background: white;
			transform: translateX(-100%);
			transition: transform 350ms;
		}

		&.active{
			a {
				opacity: 1;
			}

			&:before {
				transform: none;
			}
		}

		&:hover {
			a {
				opacity: 1;
			}
		}
	}

	.home-hero__performance-list-title,
	.home-hero__performance-list-dates {
		line-height: 1.5;
	}

	.home-hero__performance-list-dates {
		margin-top: 5px;
	}

	.home-hero__performance-thumbnail,
	.home-hero__performance-list-link {
		display: none;
	}

	.home-hero__main {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 255px;
		right: 0;
		background: black;
		overflow: hidden;

		&, h1 {
			color: white;
		}
	}

	.home-hero__performance {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		pointer-events: none;
		z-index: 0;
		transition: opacity 500ms 500ms;

		&.active {
			opacity: 1;
			pointer-events: auto;
			z-index: 1;
			transition-delay: 0;

			.home-hero__performance-content {
				opacity: 0;
				animation: FadeInSlideText 1s .5s ease-out forwards;
			}

			&.first {
				.home-hero__performance-content {
					animation: none;
				}
			}
		}
	}

	.home-hero__performance-content {
		position: absolute;
		bottom: 70px;
		left: 70px;
		right: 70px;
		max-width: 900px;
		margin: auto;
		overflow: hidden;
		text-align: right;

		h1 {
			max-width: 800px;
			text-align: left;
		}

		p {
			max-width: 450px;
			display: inline-block;
			vertical-align: top;
			text-align: left;
			text-shadow: 1px 1px 0px rgba(0, 0, 0, .3);
		}

		a.button--primary {
			display: inline-block;
			vertical-align: top;
			margin-left: 30px;
		}

		@media (max-width: break($_xl, false)) {
			text-align: left;

			p {
				display: block;
			}

			a.button--primary {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}

	.home-hero__performance-bg {
		video, img {
			z-index: -1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		img {
			z-index: -2;
		}
	}
}

@media (max-width: break($_ml, false)) {
	.home-hero__splash {
		position: relative;
		padding-top: 50px;
		padding-bottom: 70px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			background: fade-out(black, .7);
		}
	}

	.home-hero__splash-title {
		position: relative;
		z-index: 2;
		text-align: center;
		color: white;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 20px;
		z-index: 2;
	}

	.home-hero__sidebar {
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
	}

	.home-hero__sidebar-title {
		display: none;
	}

	.home-hero__performance-title {
		text-align: center;
		padding-bottom: 25px;
		border-bottom: 1px solid $c-brown-lt;
	}

	.home-hero__performance-list {
		display: block;
		position: relative;
		overflow: hidden;
		margin-left: -7.5px;
		margin-right: -7.5px;
		padding-bottom: 1px;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 7.5px;
			right: 7.5px;
			height: 1px;
			background: $c-brown-lt;
		}
	}

	.home-hero__performance-list-item {
		float: left;
		padding: 10px 7.5px;
		margin: 0;
		width: (100% / 3);
	}

	.home-hero__performance-thumbnail {
		display: block;
		width: 100%;
		height: 0;
		padding-top: (9/16) * 100%;
		background-size: cover;
	}

	.home-hero__performance-list-title {
		text-transform: uppercase;
		font-size: 1.2rem;
	  letter-spacing: 1.6px;
	  line-height: 1.5;
	  font-weight: 700;
	  margin-top: 10px;
	}

	.home-hero__performance-list-dates {
		font-size: 1.4rem;
	}

	.home-hero__performance-list-link {
		font-size: 1.3rem;
		color: $c-blue;
		text-decoration: underline;
	}

	.button--white {
		background: $c-blue;
		color: white;
	}

	.home-hero__main {
		display: none;
	}
}

@media (max-width: break($_s, false)) {
	.home-hero__sidebar {
		padding-top: 10px;
		padding-bottom: 20px;
	}
	.home-hero__performance-title {
		font-size: 1.6rem;
		padding-bottom: 10px;
	}

	.home-hero__performance-list {
		margin-left: 0;
		margin-right: 0;

		&:after {
			left: 0;
			right: 0;
		}
	}

	.home-hero__performance-list-item {
		position: relative;
		width: 100%;
		padding-left: 85px + 10px;
		padding-top: 15px;
		padding-bottom: 15px;
		text-align: left;
		border-bottom: 1px solid $c-brown-lt;

		&:last-child {
			border-bottom: 0;
		}
	}

	.home-hero__performance-thumbnail {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 85px;
		height: 48px;
		padding-top: 0;
		margin: auto;
	}

	.home-hero__performance-list-title {
		margin-top: 0;
	}

	.home-hero__performance-list-dates,
	.home-hero__performance-list-link {
		display: inline-block;
	}

	.home-hero__performance-list-link {
		margin-left: 5px;
	}

	.button--white {
		width: auto;
		display: inline-block;
	}
}

@keyframes ZoomSplashImage {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	90% {
		transform: scale(1.15);
		opacity: 1;
	}
	100% {
		transform: scale(1.15);
		opacity: 0;
	}
}

@keyframes WipeBlueBar {
	0% {
		opacity: 0;
		transform: none;
	}
	25% {
		opacity: 1;
		transform: none;
	}
	100% {
		opacity: 1;
		transform: translateX(-100%);
	}
}

@keyframes FadeInSlideText {
	0% {
		opacity: 0;
		transform: translateX(25%);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes FadeInSidebarText {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
