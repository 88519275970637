.video-play-overlay {
  //@include theme-gradient;
  display: block;
  position: absolute;
  bottom: 20px; left: 20px;
  margin: auto;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  text-align: center;
  opacity: 0.9;
  cursor: pointer;

  // Center on desktop
  @media(min-width:break($_xl)){
        bottom: 0; left: 0; right: 0; top: 0;
  }

  &:after {
    content:'';
    display: inline-block;
    position: relative;
    border-top: 18px solid rgba(255,255,255,0);
    border-bottom: 18px solid rgba(255,255,255,0);
    border-left: 25px solid rgba(255,255,255,1);
    width: 0;
    height: 0;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
  }
}

.modal.video-modal {
  z-index: 2002;

  .modal-dialog {
    max-width: (1060px + 80px);
    padding: $site-gutter--lg;
    margin: auto;
  }

  .modal-close {
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 15px;
    line-height: 1;

    .jp-icon-close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .modal-content {
    position: relative;
    padding: 0;
    width: 100%;
    height: 0;
    padding-top: 100% * (9/16);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modal-backdrop {
  z-index: 2001;
}