//3-6 Grid
.three-six-grid {
	background-color: $c-cream;
	.square-cta__item {
		flex: 1 100%;
		margin-top: 10px;
		margin-bottom: 10px;
		@media (min-width: break($_s)) {
			flex: 0 48.8%;
			margin-bottom: 5px;
		}
		@media (min-width: break($_m)) {
			margin-bottom: 10px;
		}
		@media(min-width:break($_ml)){
			flex: 0 32.2%;
		}
    @include not-mobile {
	    > a:not(:hover) {
	      .square-cta__item-text {
	        max-height: 0 !important;

	        p {
	          opacity: 0;
	        }
	      }
	    }
  	}
	}
	@media(min-width:break($_ml)){
		background-color: transparent;
	}

	.square-cta__item-link:hover {
		//opacity: 0.5;
		transition: all 0.35s;
	}

}