// Use these classes with caution, as mobile
// safari doesn't support viewport units. 
// Provide fallbacks in all use cases.
.vh1 {
  height: 100vh;
}
.vh12 {
  height: 50vh;
}
.vh13 {
  height: 33.333333vh;
}
.vh14 {
  height: 25vh;
}

.h1 {
	height: 100%;
}
