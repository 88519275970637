// WIDTHS
// I.e. "width, 4 of 5 (colums)" = 80%
.w1 { width: 100%; }
.w12 { width: 50%; }
.w13 { width: 33.33333%; }
.w23 { width: 66.66667%; }
.w14 { width: 25%; }
.w34 { width: 75%; }
.w16 { width: 16.66667%; }
.w56 { width: 83.33333%; }
.w15 { width: 20%; }
.w25 { width: 40%; }
.w35 { width: 60%; }
.w45 { width: 80%; }
@media (min-width: break($_xs)) {
  .w1_xs { width: 100%; }
  .w12_xs { width: 50%; }
  .w13_xs { width: 33.33333%; }
  .w23_xs { width: 66.66667%; }
  .w14_xs { width: 25%; }
  .w34_xs { width: 75%; }
  .w16_xs { width: 16.66667%; }
  .w56_xs { width: 83.33333%; }
  .w15_xs { width: 20%; }
  .w25_xs { width: 40%; }
  .w35_xs { width: 60%; }
  .w45_xs { width: 80%; }
}
@media (min-width: break($_s)) {
  .w1_s { width: 100%; }
  .w12_s { width: 50%; }
  .w13_s { width: 33.33333%; }
  .w23_s { width: 66.66667%; }
  .w14_s { width: 25%; }
  .w34_s { width: 75%; }
  .w16_s { width: 16.66667%; }
  .w56_s { width: 83.33333%; }
  .w15_s { width: 20%; }
  .w25_s { width: 40%; }
  .w35_s { width: 60%; }
  .w45_s { width: 80%; }
}
@media (min-width: break($_m)) {
  .w1_m { width: 100%; }
  .w12_m { width: 50%; }
  .w13_m { width: 33.33333%; }
  .w23_m { width: 66.66667%; }
  .w14_m { width: 25%; }
  .w34_m { width: 75%; }
  .w16_m { width: 16.66667%; }
  .w56_m { width: 83.33333%; }
  .w15_m { width: 20%; }
  .w25_m { width: 40%; }
  .w35_m { width: 60%; }
  .w45_m { width: 80%; }
}
@media (min-width: break($_ml)) {
  .w1_ml { width: 100%; }
  .w12_ml { width: 50%; }
  .w13_ml { width: 33.33333%; }
  .w23_ml { width: 66.66667%; }
  .w14_ml { width: 25%; }
  .w34_ml { width: 75%; }
  .w16_ml { width: 16.66667%; }
  .w56_ml { width: 83.33333%; }
  .w15_ml { width: 20%; }
  .w25_ml { width: 40%; }
  .w35_ml { width: 60%; }
  .w45_ml { width: 80%; }
}
@media (min-width: break($_l)) {
  .w1_l { width: 100%; }
  .w12_l { width: 50%; }
  .w13_l { width: 33.33333%; }
  .w23_l { width: 66.66667%; }
  .w14_l { width: 25%; }
  .w34_l { width: 75%; }
  .w16_l { width: 16.66667%; }
  .w56_l { width: 83.33333%; }
  .w15_l { width: 20%; }
  .w25_l { width: 40%; }
  .w35_l { width: 60%; }
  .w45_l { width: 80%; }
}
@media (min-width: break($_xl)) {
  .w1_xl { width: 100%; }
  .w12_xl { width: 50%; }
  .w13_xl { width: 33.33333%; }
  .w23_xl { width: 66.66667%; }
  .w14_xl { width: 25%; }
  .w34_xl { width: 75%; }
  .w16_xl { width: 16.66667%; }
  .w56_xl { width: 83.33333%; }
  .w15_xl { width: 20%; }
  .w25_xl { width: 40%; }
  .w35_xl { width: 60%; }
  .w45_xl { width: 80%; }
}
@media (min-width: break($_xxl)) {
  .w1_xxl { width: 100%; }
  .w12_xxl { width: 50%; }
  .w13_xxl { width: 33.33333%; }
  .w23_xxl { width: 66.66667%; }
  .w14_xxl { width: 25%; }
  .w34_xxl { width: 75%; }
  .w16_xxl { width: 16.66667%; }
  .w56_xxl { width: 83.33333%; }
  .w15_xxl { width: 20%; }
  .w25_xxl { width: 40%; }
  .w35_xxl { width: 60%; }
  .w45_xxl { width: 80%; }
}
