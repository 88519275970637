.card {
	display: block;
  position: relative;
  overflow: hidden;
  .curated &, .four-up-cards & {
	  width: 100%;
  	margin: 10px 0;
	  @media(min-width:break($_s)){
	  	width: 47.3%;
		}
	  @media(min-width:break($_ml)){
		  margin: 15px 0;
		  width: 23.5%;
	  }
	  @media(min-width:break($_xl)){
		  &:hover {
		  	.card__content:not(.no-link) {
			  	transition: transform 0.2s ease-in;
			  	transform: translateY(-30px);
		  	}
		  	.card__link {
					opacity: 1;
					transition: opacity 0.2s ease-in;
		  	}
		  }
		}
	}
}

.card__img {
    display: block;
}

.card--ghost {
	display: none;

	@media (min-width: break($_ml)) {
		display: block;
	}
}

.card__content {
	padding: 15px 20px 55px;
	@media(min-width:break($_xl)){
		position: relative;
    background: #FFF;
    transition: transform 0.2s ease;
    padding: 20px 20px 55px;
	}
}

.card__link {
	z-index: 1;
}

.card__link-text {
	position: absolute;
	bottom: 30px;
	width: 100%;
	@media(min-width:break($_xl)){
		opacity: 0;
		transition: opacity 0.1s ease-out;
	}
}

.card--no-excerpt {
	.card__content {
		@media (min-width: break($_xl)) {
			padding-bottom: 65px;
		}
	}
}
