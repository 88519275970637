//Sidebar
//This section is not using rte styling since font size is smaller so these styles are the same as rte styles in typography.css
.sidebar {
	a {
		@extend %a;
	}
	p {
		@extend %p;
	}
	.button--primary {
		a, a:hover {
			@extend .c-white;
			text-decoration: none;
		}
	}
}