.hero {
	position: relative;
	margin-bottom: 0;
}

.landing-hero {
	+ section:not(.bg-c-cream) {
		margin-top: $section-spacing / 2;
	 	@media(min-width:break($_s)){
    	margin-top: $section-spacing--lg / 2;
  	}
	}
}

.slick-list {
		overflow: hidden;
}

.hero__content {;
	width: 100%;
	padding: 0 $site-gutter;
	position: relative;
	text-align: center;
	padding: 20px $site-gutter;
	@media(min-width:break($_ml)){
		padding: 30px $site-gutter 0;
		left: 50%;
		position: absolute;
		top: 75%;
		transform: translate(-50%, -75%);
		color: $c-white;
	}
}

.hero__slide-bg {
	padding-top: 56.25%; 
	position: relative;
	@media(min-width:break($_xxl)){
		padding-top: 50.25%;
	}
}

.hero__title {
	text-align: center;
	@media(min-width:break($_ml)){
			color: $c-white;
	}
}

.hero__excerpt {
	@media(min-width:break($_ml)){
		text-shadow: 1px 1px 0px rgba(0, 0, 0, .3);
	}
	@media(min-width:break($_xl)){
		max-width: em(550px);
	}
}

.hero__title + .hero__button-wrapper {
	margin-top: 20px;
}

.hero__button-wrapper {
	text-align: center;
}

.hero__button {
	margin: 10px auto 0;
	@media(min-width:break($_s)){
		margin: 0 10px;
	}
}

.js {
	.hero {
		//@extend .trans-all;
		visibility: none;
		opacity: 0;

		&.slick-initialized {
			visibility: visible;
			opacity: 1;
		}
	}
}

.slick-slider {
	margin-bottom: 0;
}


.slick-slide {
	.landing-hero & {
		max-height: 685px;
	}
}

.slick-dots {
	.landing-hero & {
		//z-index: 1100;
		position: relative;
		bottom: 0;
		margin: 15px 0;
		@media(min-width:break($_ml)){
			position: absolute;
			bottom: 15px;
		}
	}
}

.slick-dots li.slick-active button:before{
	content: $slick-active-dot-character;
	font-family: $slick-font-family;
	font-size: $slick-dot-size;
	opacity: 1;
	@media(min-width:break($_ml)){
		color: $c-white;
	}
}

.slick-dots li button:before {
	content: $slick-dot-character;
	font-family: $slick-font-family;
	font-size: $slick-dot-size;
	opacity: 1;
	@media(min-width:break($_ml)){
		color: $c-white;
	}
}

