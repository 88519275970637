%flickity-fade-wrapper {
  .flickity-viewport {
    transition: height 0.6s;
  }
  .flickity-slider {
    transform: none !important;
  }
}

%flickity-fade-item {
  width: 100%;
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;

  &.is-selected {
    opacity: 1;
    z-index: 0;
  }
}

%flickity-as-nav {
  .flickity-prev-next-button {
    width: 15px;
    height: 15px;
    transition: all 0.3s;
    background: transparent;
    padding: 0;
    border-radius: 0;

    &:disabled {
      visibility: hidden;
      opacity: 0;
    }
    &:hover {
      background-color: transparent;
    }

    svg {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      path {
        fill: $c-blue;
      }
    }
  }
}

%flickity-as-nav-gradient {
  .flickity-prev-next-button {
    top: 0;
    bottom: 0;
    left: 0 !important;
    width: 36%;
    height: auto;
    transform: none;
    pointer-events: none;

    &,
    &:hover {
      background-image: linear-gradient(-90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 83%);
    }

    svg {
      left: 10px;
      width: 15px;
      pointer-events: auto;
    }

    &.next {
      left: auto !important;
      right: 0 !important;

      &,
      &:hover {
        background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 83%);
      }

      svg {
        left: auto;
        right: 10px;
      }
    }
  }
}