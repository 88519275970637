.ui-datepicker {
	width: 280px;
	display: none;
	background: white;
	margin-top: 20px;
	border: 1px solid $c-brown-lt;
	box-shadow: 0 0 6px fade-out(black, .9);
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		width: 15px;
		height: 15px;
		background: inherit;
		border: inherit;
		box-shadow: inherit;
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
		z-index: -1;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 20px;
		margin: auto;
		background: white;
		z-index: 0;
	}

	@media (max-width: break($_ml, false)) {
		&:before {
			left: 22%;
		}
	}
}
.ui-datepicker .ui-datepicker-header {
	position: relative;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: 0;
	width: 40px;
	height: 60px;
}
// .ui-datepicker .ui-datepicker-prev-hover,
// .ui-datepicker .ui-datepicker-next-hover {
// }
.ui-datepicker .ui-datepicker-prev {
	left: 0;
}
.ui-datepicker .ui-datepicker-next {
	right: 0;
}
// .ui-datepicker .ui-datepicker-prev-hover {
// }
// .ui-datepicker .ui-datepicker-next-hover {
// }
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
	@extend i;
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	font-size: 20px;
}
.ui-datepicker .ui-datepicker-prev span {
	@extend .jp-icon-arrow-thin-left;
}
.ui-datepicker .ui-datepicker-next span {
	@extend .jp-icon-arrow-thin-right;
}
.ui-datepicker .ui-datepicker-title {
	text-align: center;
	line-height: 60px;
	margin: 0 40px;
}
// .ui-datepicker .ui-datepicker-title select {

// }
// .ui-datepicker select.ui-datepicker-month,
// .ui-datepicker select.ui-datepicker-year {

// }
.ui-datepicker table {
	width: 100%;
	border-collapse: collapse;
}
.ui-datepicker th {
	padding: 0;
	padding-bottom: 4px;
	border-bottom: 1px solid $c-brown-lt;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
}
.ui-datepicker td {
	padding: 0;
	font-size: 12px;
	border-right: 1px solid $c-brown-lt;
	border-bottom: 1px solid $c-brown-lt;

	&:last-child {
		border-right: 0;
	}

	&.ui-datepicker-today {
		position: relative;
		background: $c-blue;
		color: white;

		> a {
			position: absolute;
			top: -1px;
			bottom: -1px;
			left: -1px;
			right: -1px;
			padding-top: 11px;
			padding-bottom: 11px;
			background: $c-blue;
		}
	}

	&:not(.ui-datepicker-today) {
		> a:hover {
			color: white;
			background: $c-blue;
		}
	}
}
.ui-datepicker tr:last-child td {
	border-bottom: 0;
}
.ui-datepicker td span,
.ui-datepicker td a {
	display: block;
	text-align: center;
	padding: 10px 0;
	text-decoration: none;
	
	&:hover {
		color: $c-blue;
	}
}
