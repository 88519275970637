.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: none;
  overflow: hidden;
  outline: 0;
  transition: opacity $trans-duration ease-in-out;
  -webkit-overflow-scrolling: touch;
  
  .modal-dialog {
    max-width: 950px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  // When fading in the modal, animate it to slide down
 /*  &.u-fade .modal-dialog {
    transition: transform 500ms;
    transform: translate(0, 25%);
  }

  &.in .modal-dialog {
    transform: translate(0, 0);
  } */

  @media(min-width:break($_m)){
	 	padding-left: 0;
    padding-right: 0;

    .modal-dialog {
      margin: 0;
    }
  }
}

body.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: auto;
}

.modal-content {
  position: relative;
  background-color: white;
  background-clip: padding-box;
  outline: 0;
}

.modal-header {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: black;
  transition: opacity $trans-duration ease-in-out;
  // Fade for backdrop
  &.u-fade { opacity: 0; }
  &.in { opacity: 0.5; }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-close {
  display: block;
  position: absolute;
  height: 70px;
  width: 70px;
  z-index: 10;
  right: 0;
  font-size: 20px;
  line-height: 70px;
  background: none;
  border: 0;
  color: white;
  cursor: pointer;
  outline: 0;
}
