.overlay {
	.landing-hero &, .home-hero-yr &, .home-hero &{
	pointer-events: none;
   	@media(min-width:break($_ml)){
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
			bottom: 0;
	    background: linear-gradient(0deg, rgba(0,0,0,0.5) 20%, transparent 100%);
  	}
	}
}