//List Image Item
.list-image__item {
	align-items: flex-start;
	margin-bottom: 40px;
	@media(min-width:break($_xl)){
		align-items: center;
	}
	.paragraph-list-image & {
		align-items: flex-start;
		margin-bottom: 0;
		&:after {
			@extend .o-divider-line;
			content: '';
			position: relative;
			margin: 35px 0;
			display: block;
			width: 100%;
		}
		&:last-of-type {
			&:after {
				content: none;
			}
		}
	}
	&:last-of-type {
		margin-bottom: 0;
	}
}

.list-image__item-img-wrapper {
	width: 100%;
	@media(min-width:break(639px)){
		width: 50%;
	}
	@media(min-width:break($_xl)){
		width: 60%;
	}
	//Styles specific to Paragraph List w/ Images Module
	.paragraph-list-image & {
		width: 33%;
		@media(min-width:break($_s)){
			width: 25%;
		}
	}
}

.list-image__item-img {
	padding-top: 56.25%;
	.paragraph-list-image & {
		padding-top: 100%;
	}
}

.list-image__item-content {
	width: 100%;
	margin-top: 20px;
	@media(min-width:break(639px)){
		width: 46%;
		margin-top: 0;
	}
	@media(min-width:break($_xl)){
		width: 36%;
	}
	//Styles specific to Paragraph List w/ Images Module
	.paragraph-list-image & {
		width: 62%;
		margin-top: 0;
		padding-left: 4%;
		@media(min-width:break($_s)){
			width: 75%;
			margin-top: 0;
		}
		h4 {
			//extend .rte .subheading
			font-size: 1.6rem;
			font-weight: 700;
			text-transform: none;
			letter-spacing: .25px;
			text-align: left;
			line-height: 1.3;
		}
	}
}