.comparison-grid {
  padding: 80px 0;

  .accordion & {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:before {
    display: none !important;
  }
}

.comparison-grid__container {
  max-width: 1063px;
  margin: 0 auto;
}

.comparison-grid__header {
  @extend %flickity-as-nav;
  @extend %flickity-as-nav-gradient;
  @extend %flickity-disable-grab-cursor;
  
  display: flex; // Set temporary without flickity
  border: 1px solid $c-brown-lt;
  padding: 20px 0;

  .flickity-viewport {
    width: 100%;
  }
}

.comparison-grid__header-item {
  width: 54.5%;
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 10px 25px;
  cursor: pointer;

  @media (min-width: break($_s)) {
    max-width: 370px;
  }
  @media (min-width: break($_m)) {
    width: percentage(1/3);
    padding: 40px 25px 30px;
    
    &:nth-child(2){
      border-left: 1px solid $c-brown-lt;
      border-right: 1px solid $c-brown-lt;
    }
  }

  &.is-nav-selected {
    color: $c-blue;
  }
}

.comparison-grid__header-title {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  @media (min-width: break($_s)) {
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 30px;
  }
}

.comparison-grid__header-tagline {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  @media (min-width: break($_s)) {
    font-size: 15px;
    line-height: 22px;
  }
}

.comparison-grid__tables {
  &.flickity-enabled {
    @extend %flickity-fade-wrapper;
    @extend %flickity-disable-grab-cursor;

    .flickity-viewport {
      transition: none;
    }
  }
}

.comparison-table {
  .flickity-slider & {
    @extend %flickity-fade-item;
  }
}

.comparison-table__bar-toggle,
.comparison-table__bar-close {
  &:after {
    @extend i;
    display: inline-block;
    font-size: 7px;
    transform: translateY(-2px);
    margin-left: 4px;
  }
}

.comparison-table__bar-close {
  @extend %content;
  @extend %a;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: none;
  appearance: none;
  padding: 0;
  text-transform: none;

  &:after {
    @extend .jp-icon-arrow-up:before;
  }
}
.comparison-table__bar-text {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 10px 20px;
  background: $c-blue;
  color: $c-white;
  text-align: center;
  font-size: 1.4rem;
  
  @media (min-width: break($_m)) {
    flex-flow: row;
    justify-content: center;
    text-align: left;
    font-size: 1.6rem;
    letter-spacing: 0.25px;
  }
}

.comparison-table__bar-toggle {
  margin-top: 5px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.5;
  cursor: pointer;

  &:after {
    @extend .jp-icon-arrow-down:before;
  }

  &.js-info-bar-active {
    &:after {
      @extend .jp-icon-arrow-up:before;
    }
  }

  @media (min-width: break($_m)) {
    font-size: 1.25rem;
    margin-top: 2px;
    margin-left: 20px;
  }
}

%bullet {
  display: table;
  margin-bottom: 8px;
  page-break-inside: avoid;
  break-inside: avoid-column;

  &:before {
    content: '•';
    display: table-cell;
    padding-right: 12px;
  }
}

.comparison-table__bar-body {
  padding: 25px 20px;
  border-left: 1px solid $c-brown-lt;
  border-right: 1px solid $c-brown-lt;
  border-bottom: 1px solid $c-brown-lt;
  display: none;

  @media (min-width: break($_m)) {
    padding: 45px 85px;
  }
  p, li {
    font-size: 1.4rem;

    @media (min-width: break($_m)) {
      font-size: 1.6rem;
    }
  }
  h4 {
    margin-bottom: 10px;
    text-transform: none;
    letter-spacing: 0;
    @media (min-width: break($_m)) {
      margin-bottom: 20px;
    }
  }
  ul {
    padding-left: 10px;
    margin-bottom: 10px;
    list-style: none;
    @media (min-width: break($_m)) {
      margin-top: 15px;
      column-count: 3;
      column-gap: 60px;
    }
    li {
      @extend %bullet;
    }
  }
}

.comparison-table__header-image {
  @extend .u-bg-cv;
  background-color: $c-gray-lt;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  @media (min-width: break($_m)) {
    height: 200px;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}

.comparison-table__header-text {
  @extend .f4;
  position: relative;
  padding: 0 20px;
  color: $c-white;
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
}

.comparison-table__inner {
  width: 100%;

  @media (min-width: break($_m)) {
    display: table;
    table-layout: fixed;
  }
}

.comparison-table__row--header {
  @media (min-width: break($_m)) {
    display: table-header-group;
  }
}

.comparison-table__row {
  display: none;
  
  @media (min-width: break($_m)) {
    display: table-row;
    width: 100%;
  }
}

.comparison-table__row--header {
  width: 100%;
  display: block;

  @media (min-width: break($_m)) {
    display: table-row;
  }
}

.comparison-table__cell {
  padding: 12px 20px 0;
  vertical-align: middle;

  @media (min-width: break($_m)) {
    display: table-cell;
    padding: 30px;
    border-right: 1px solid $c-brown-lt;
    border-bottom: 1px solid $c-brown-lt;
    text-align: center;
    color: $c-blue;
  }

  &.accordion {
    .accordion__item-content {
      @media (min-width: break($_m)) {
        display: none !important;
      }
    }
  }
}

.comparison-table__cell-heading {
  font-weight: 700;
  color: $c-brown;
  text-transform: uppercase;
  font-size: 1.3rem;
  letter-spacing: 1.5px;

  span {
    @media (min-width: break($_m)) {
      display: block;
    }
    
    &:after {
      content: ' ';

      @media (min-width: break($_m)) {
        content: none;
      }
    }
  }
}

.comparison-table__cell-list {
  padding-left: 5px;
  line-height: 1.8rem;
  list-style-type: none;
  
  @media (min-width: break($_m)) {
    display: none;
  }
}

.comparison-table__cell-list-item {
  @extend %bullet;
  margin: 12px 0 0;

  &:first-child {
    margin-top: 0;
  }
}

.comparison-table__cell--checked {
  background-image: url('../img/icon-check.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.comparison-table__cell--title {
  display: none;
  width: 315px;
  color: $c-brown;
  background-color: $c-cream;
  text-align: left;
  border-left: 1px solid $c-brown-lt;
  line-height: 2.5rem;

  @media (min-width: break($_m)) {
    display: table-cell;
  }
}
