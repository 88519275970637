// OBJECTS
// Objects are custom elements, and should be
// prefixed with '.o-*' to inform collaborators.

.o-divider-line-faded {
 	@extend .block;
	@extend .align-c;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba($c-brown, 0.14) 10%, rgba($c-brown, 0.14) 90%, rgba(0, 0, 0, 0) 100%);
}
@mixin o-divider-line-faded {
  display: block;
  text-align: center;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba($c-brown, 0.14) 10%, rgba($c-brown, 0.14) 90%, rgba(0, 0, 0, 0) 100%);
}

.o-divider-line {
 	@extend .block;
	@extend .align-c;
  border: 0;
  height: 1px;
  background-color: rgba($c-brown, 0.14);
}

.o-divider-line-v {
 	@extend .block;
  border: 0;
  height: 1px;
  background-color: rgba($c-brown, 0.14);
}
@mixin o-divider-line-v {
  display: block;
  border: 0;
  height: 1px;
  background-color: rgba($c-brown, 0.14);
}