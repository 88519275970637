//Event About 

.event-about {
+ section:not(.bg-c-cream) {
		&:before {
			content: '';
			@extend .o-divider-line-faded;
			position: relative;
			width: 90%;
			display: block;
			margin: 0 auto $section-spacing;
			@media(min-width:break($_s)){
	    	margin-bottom: $section-spacing--lg;
	  	}
		}
	}
}

.event-about__title, .event-about__content, .event-about__sidebar {
	flex: 1 100%;
}

.event-about__content {
	@media(min-width:break($_ml)){
		flex: 2 75%;
	}
	@media(min-width:break($_xl)){
		flex: 2 55%;
		max-width: em(700px);
	}
}

.event-about__sidebar {
	.social__list {
		display: inline-block;
		li {
			border-radius: 50%;
	    border: 1px solid $c-blue;
	    height: 26px;
	    width: 26px;
	    line-height: 26px;
	    text-align: center;
	    margin: 0 0 0 5px;
	    transition: color $trans-duration, background-color $trans-duration;
	    &:hover {
				background-color: $c-blue;
				.social__icon {
					color: $c-white;
				}
	    }
		}
		.social__icon {
			font-size: .75rem;
		}
	}
	&:before {
		content: '';
    @extend .o-divider-line-faded;
    position: relative;
    width: 100%;
    display: block;
    margin: 20px auto;
    @media(min-width:break($_s)){
      margin: 30px auto;
    }
	}
	@media(min-width:break($_ml)){
		flex: 1 21%;
		padding-left: 4%;
		&:before {
			content: none;
		}
	}
}

.event-about__title {
	@media(min-width:break($_xl)){
		flex: 0 15%;
	}
}

.more-content {
	display: none;
}