.three-up-grid {
  &.bg-c-cream {
    padding-bottom: 20px;
    @media (min-width: break($_l)) {
      padding-bottom: 45px;
    }
  }
}

.three-up-grid__inner {
  padding-top: 15px;
}

.three-up-grid__headline {
  @extend .f2;
  text-align: center;
}

.three-up-grid__tagline {
  @extend %p;
  text-align: center;
}

.three-up-grid__list {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
  @media (min-width: break($_l)) {
    margin-top: 35px;
  }
}

.three-up-grid__item {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  width: calc(50% - 20px);
  flex: 0 0 auto;
  margin: 0 10px 20px;
  background-color: $c-white;
  @media (min-width: break($_l)) {
    width: calc(33.33% - 20px);
  }
  &:hover {
    .three-up-grid__item-title {
      transform: translateY(-30px);
    }
  }
}

.three-up-grid__item-image {
  @include aspect(130, 73);
  @extend .u-bg-cv;
  width: 100%;
}

.three-up-grid__item-title {
  @extend .f5;
  margin: 0 -10px 30px;
  padding: 5px 10px 0;
  background: $c-white;
  transition: transform 0.2s ease !important;
  @media (max-width: break($_s, false)) {
    font-size: 1rem;
    line-height: 1.5em;
    letter-spacing: 0.12em;
  }
  @media (min-width: break($_l)) {
    margin: 0 0 55px 0;
    padding: 15px 35px 0;
    background-color: $c-white;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: .2em;
  }
}

.three-up-grid__tagline {
  margin: 10px auto;
  max-width: 800px;
}

.three-up-grid__item-content {
  position: relative;
  width: 100%;
  flex: 1 0 auto;
  padding: 10px;
  background: $c-white;
  text-align: center;
  transition: all 0.3s ease;
  @media (min-width: break($_l)) {
    padding: 0 0 15px 0;
  }
}

.three-up-grid__item-more {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  padding: 0 10px;
  text-align: center;
  @media (min-width: break($_l)) {
    bottom: 30px;
  }
  span {
    color: $c-blue;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    border-bottom: 1px solid $c-blue;
    @media (min-width: break($_l)) {
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.25px;
    }
  }
}