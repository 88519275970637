$quote-carousel-max-width: 1200px !default;

section.quote-carousel {
  @media (min-width: break($_l)) {
    max-width: $quote-carousel-max-width;
  }

  .carousel-controls {
    @media (min-width: break($_l)) {
      margin-top: 15px;
    }
  }

  &:before {
    @media (min-width: break($_l)) {
      margin-bottom: 25px !important;
    }
  }
}

.quote-carousel__inner {
  padding: 25px 0;
  @media (min-width: break($_l)) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px 0;
  }
}

.quote-carousel__headline {
  @extend .f7;
  text-align: center;
  margin-bottom: 12px;

  @media (min-width: break($_l)) {
    margin-bottom: 25px;
  }
}

.quote-carousel__aside {
  width: 100%;
  @media (min-width: break($_l)) {
    width: 50.5%;
    max-width: 680px;
  }
  @media (min-width: break($quote-carousel-max-width + 80px)) {
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
  }
}

.quote-carousel__primary {
  padding-top: 30px;
  width: 100%;
  
  @media (min-width: break($_l)) {
    max-width: 570px;
    width: 49.5%;
    padding: 0 40px;
  }
  @media (min-width: break($quote-carousel-max-width + 80px)) {
    max-width: 490px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
}

.quote-carousel__slide {
  text-align: center;
}

.quote-carousel__button-play {
  @extend .f5;
  margin: auto;
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 135px;
  height: 135px;
  display: inline-flex;
  background: rgba($c-blue, 0.75);
  color: $c-white;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.quote-carousel__title {
  @extend .f7;
}

.quote-carousel__quotes-wrapper {
  @media (min-width: break($_l)) {
    min-height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.quote-carousel__quotes {
  margin-bottom: 15px;
  flex: 1;

  @media (min-width: break($_l)) {
    width: 100%;
    flex: 0;
    margin-bottom: 20px;
  }
}

.quote-carousel__description {
  width: 100%;
  margin: 0;

  @media (max-width: break($_s)) {
    height: 80px;
  }

  &.long-text {
    height: auto;
  }

  p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.quote-carousel__description-link {
  @extend %p;
  display: none;
  width: auto;
  max-width: inherit;
  padding-left: 10px;
}

.quote-carousel__author {
  @extend .f5;
  margin-top: 15px;

  @media (min-width: break($_l)) {
    margin-top: 20px;
  }
}

.quote-carousel__position {
  @extend %typo-quote-carousel__position;
}

.quote-carousel--right {
  .quote-carousel__aside {
    order: 1;
  }
  .quote-carousel__primary {
    order: 0;
  }
}

.quote-carousel__slider {
  .flickity-slider {
    transform: none !important;
  }
  .flickity-viewport {
    transition: height 0.3s;
  }
}

.quote-carousel__slide {
  width: 100%;
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;

  &.is-selected {
    opacity: 1;
    z-index: 0;
  }
}

.quote-carousel__primary-content {
  @media (min-width: break($_l)) {
    margin-bottom: 15px;
  }
}

.quote-carousel__content {
  .flickity-viewport {
    height: 100% !important;
  }
}

.quote-carousel__pagination {
  @media (min-width: break($_l)) {
    position: absolute;
    bottom: -15px;
    left: 25%;
    transform: translateX(-50%);
  }
  @media (min-width: break($quote-carousel-max-width + 80px)) {
    bottom: 0;
    left: 50%;
  }
}