section.calendar-listing {
  position: relative;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   height: 1px;
  //   @extend .o-divider-line-faded;

  //   @media (max-width: break($_ml, false)) {
  //     display: none;
  //   }
  // }
  @media (min-width: $_m) {
    padding-left: $site-gutter--lg;
    padding-right: $site-gutter--lg;
  }
  @media (max-width: $_m, false) {
    .calendar-week {
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 20px;
    }
  }
}

.fc table {
  font-size: 15px;
}

.fc-ltr .fc-axis {
  display: none;
}

.fc-day-grid-event .fc-content {
  white-space: normal;
}

.fc-time-grid .fc-slats td {
  height: 4.5em;
  border-bottom: 0;
}

.fc-event {
  background: transparent;
  font-size: 12px;
  margin: 0;
  padding: 10px;
  border: 0;
  color: $c-brown;

  &:hover {
    background: none !important;
    color: $c-blue;
  }
  .calendar-filtered & {
    opacity: 0.3;

    @media (max-width: break($_m, false)) {
      display: none;
    }
  }
  &.event-past {
    opacity: 0.3 !important;
  }
  &.js-is-selected {
    opacity: 1;

    @media (max-width: break($_m, false)) {
      display: block;
    }
  }
  .fc-title {
    display: block;
    font-weight: bold;

    .calendar-key__diamond {
      position: relative;
      margin: 0;
      margin-left: 5px;
      top: -1px;
    }
  }
  .fc-time,
  .fc-sold-out {
    font-weight: normal;
  }

  .fc-sold-out {
    display: block;
  }
}

.fc th {
  padding: 10px;
  text-align: left;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .date {
    font-weight: bold;
  }
}

.fc-unthemed .fc-today {
  background: none;
}

.fc-day-header.day-current {
  position: relative;
  color: $c-blue;

  &:before {
    position: absolute;
    content: '';
    top: -1px;
    left: -1px;
    right: -1px;
    height: 2px;
    background: $c-blue;
  }
}

.fc-day-header.day-past > * {
  opacity: 0.3;
}

//Setting min height of grid cells to 135px
.fc-basicWeek-view .fc-content-skeleton, .fc-basicDay-view .fc-content-skeleton {
  height: 100%;
  min-height: em(135px);
}

.calendar-listing__bottom {
  overflow: hidden;

  .local-nav__scroll-top {
    display: block;
    float: right;
    margin-left: 5px;
    transition: background-color $trans-duration, color $trans-duration;
    &:hover{
      color: $c-blue-dk;
    };

    @media (max-width: break($_ml, false)) {
      display: none;
    }
  }

  @media (max-width: break($_m, false)) {
    text-align: center;
    padding-left: $site-gutter;
    padding-right: $site-gutter;
  }
}

.calendar-listing__next-page {
  display: inline-block;
  color: $c-blue;
  text-decoration: underline;

  @media (max-width: break($_m, false)) {
    margin-bottom: 15px;
  }

  @media (max-width: break($_s, false)) {
    font-size: 12px;
  }
}

.calendar-week {
  position: relative;
  margin-bottom: 30px;
  border-bottom: 1px solid $c-brown-lt;

  // &:hover:not(.open) {
  //   border-bottom-color: $c-blue;
  // }

  &:last-child {
    margin-bottom: 20px;

    @media (max-width: break($_m, false)) {
      margin-bottom: 10px;
    }
  }

  .fc td{
    border-bottom: 0;
  }

  @media (min-width: break($_m)) {
    transition: margin-bottom 350ms;

    &.open {
      margin-bottom: 70px;

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
}

.calendar-week__blank-slate {
  text-align: center;

  .calendar-week__desktop & {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: fade-out(white, .4);
    z-index: 1;

    > * {
      position: absolute;
      top: 62%; // Band-aid to center text relative to grid row and not entire container
      left: 0;
      right: 0;
      transform: translateY(-62%);
    }
  }

  .calendar-week__mobile & {
    padding: 10px;
    padding-top: 0;
    padding-bottom: 40px;
  }

  &.calendar-week__blank-slate--filter {
    display: none;

    .calendar-week__mobile.filtered-out & {
      display: block;
    }
  }
}

.calendar-week__header {
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 11px;
  padding-right: 75px;
  margin-bottom: -1px;
  transition: color 350ms;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: $c-blue;
    opacity: 0;
    transition: opacity 350ms;
  }

  h3 {
    &.active {
      span.collapse-opened {
      display: inline-block;
      }
      span.collapse-closed {
        display: none;
      }
    }
    span.collapse-opened {
      display: none;
    }
    span.collapse-closed {
      display: inline-block;
    }
  }

  @media (max-width: break($_m, false)) {
    padding-right: 65px;
    .calendar-week__collapse {
       right: 20px;
    }
  }

  @media (max-width: break($_m, false)) and (min-width: break($_s)) {
    padding-left: 260px;
  }

  @media (max-width: break($_s, false)) {
    padding-left: 20px;
    padding-right: 20px;
    //padding-right: 0;
    padding-bottom: 0;
  }

  * {
    color: inherit;
  }

  .calendar-week:not(.open) &:hover {
    color: $c-blue;

    &:after {
      opacity: 1;
    }
  }

  h3 {
    float: left;
    width: 300px;
    margin-bottom: 20px;

    @media (max-width: break($_xl, false)) {
      width: 240px;
    }

    @media (max-width: break($_m, false)) {
      width: 230px;
    }

    @media (max-width: break($_m, false)) and (min-width: break($_s)) {
      margin-left: -230px;
    }
  }

  .calendar-key {
    position: relative;
    float: right;
    width: 170px;
    padding-left: 13px;
    color: $c-brown;
    transition: opacity 350ms;

    .calendar-key__diamond {
      position: absolute;
      top: 5px;
      left: 0;
    }

    .calendar-week--blank & {
      display: none;
    }
  }

  .calendar-week__collapse.collapsed + .calendar-key {
    opacity: 0;
  }

  @media (max-width: break($_ml, false)) {
    .calendar-key {
      display: none;
    }
  }
}

.calendar-week__performing {
  margin-bottom: 20px;
  pointer-events: none;

  @media (max-width: break($_s, false)) {
    float: left;
    clear: left;
  }

  .calendar-week.open & {
    pointer-events: auto;
    .calendar-week__performing-link:hover {
      color: $c-blue;
      transition: color $trans-duration ease-in-out;
    }
  }

}

.calendar-week__collapse {
  position: absolute;
  top: 0;
  right: 0;
  // float: right;
  width: 75px;
  text-align: right;

  .collapse-closed {
    display: none;
    color: $c-gray;
  }
  .collapse-opened {
    display: inline-block;
    color: $c-blue;
  }

  &.collapse-only {
    .collapse-closed {
      display: inline-block !important;
    }
    .collapse-opened {
      display: none !important;
    }
  }

  span > span {
    text-decoration: underline;
  }

  &[aria-expanded="true"] {
    .collapse-closed {
      display: inline-block;
    }
    .collapse-opened {
      display: none;
    }
  }

  @media (max-width: break($_m, false)) {
    font-size: 1.3rem;
    width: 65px;
  }

  &.calendar-week__collapse--bottom {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: auto;
    bottom: -28px;
    font-size: 1.3rem;
    width: 65px;
    transition: opacity 350ms;

    .calendar-week.open & {
      opacity: 1;
      pointer-events: auto;
    }

    @media (max-width: break($_m, false)) {
      display: none;
    }
  }

  .calendar-week__mobile & {
    position: relative;
    float: right;
    padding: 10px 0 20px;
  }

  .calendar-week__mobile.filtered-out & {
    display: none;
  }
}

.calendar-week__content {
  overflow: hidden;
  padding: 0 1px;
  margin: 0 -1px;
  @media (max-width: break($_m, false)) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.collapsing {
    transition: .35s ease;
    transition-property: height, visibility;
  }
}

.calendar-week__mobile-event {
  @extend .fc-event;
  display: block;
  float: left;
  clear: left;
  width: 100%;
  padding-left: 95px;
  padding-right: 0;
  border-radius: 0;
}

.calendar-week__mobile-date {
  position: relative;
  float: left;
  clear: left;
  width: 85px;
  padding: 10px;
  padding-left: 0;
  border-top: 1px solid $c-brown-lt;
  z-index: 1;

  &.day-current {
    color: $c-blue;
    border-top-color: $c-blue;
  }

  + .calendar-week__mobile-event {
    border-top: 1px solid $c-brown-lt;
    clear: none;
    padding-top: 13px;
    padding-left: 85px + 10px;
    margin-left: -85px;
  }

  &.filtered-out {
    display: none;
  }
}

.calendar-week__desktop {
  display: none;
}

.calendar-week__mobile {
  display: block;
}

@media (min-width: break($_m)) {
  .calendar-week__desktop {
    display: block;
  }
  .calendar-week__mobile {
    display: none;
  }
}

.calendar-key {
  @extend %caption;
  margin-right: 25px;
  @media (min-width: break($_m)) {
      margin-right: 40px;
  }
}

.calendar-key__diamond {
  display: inline-block;
  position: relative;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  background: $c-blue;
  transform: rotate(45deg);
}