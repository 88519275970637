// UTILIES - prefixed with .u-
.u-clear {
	&::before,
	&::after {
	  content:' ';
	  display: table;
	}

	&::after {
	  clear: both;
	}
}
.u-inline-helper {
	&::after {
		@extend .inline-block;

		content:'';
		width: 100%;
	}
}
.u-middle-align-helper {
	&::after {
		@extend .inline-block;

		content:'';
		height: 100%;
		vertical-align: middle;
	}
}
.u-fill {
	bottom: 0; left: 0; right: 0; top: 0;
}

//Video
.u-video-container{
  overflow: hidden;
}

.u-video{
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  opacity: 0;
}

// Backgrounds
.u-bg-cv {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.u-fade {
  opacity: 0;
  transition: opacity $trans-duration;

  &.in {
    opacity: 1;
  }
}

.u-bg-cn {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

// Shapes
.u-round{
  border-radius:50%;
}

// Images
.u-svg{
  position: relative;

  svg{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
  }
} 

.u-img{
  position: relative;

  img{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
  }
} 

.u-z1 {
  z-index: 1;
}

