.video-loader {
  z-index: 997;
  //background-color: $c-black;
  .touchevents & {
    display: none;
  }
}

.dot-loader {
  width: 5.6rem;
  height: 1.2rem;
  margin: auto;

  .dot {
    width: 1.2rem;
    height: 1.2rem;
    background: $c-blue;
    border-radius: 1.2rem;
    display: block;
    float: left;
    margin-right: 1rem;
    opacity: 0.2;
  }

  .dot-1 {
    animation: loader 1s infinite;
  }

  .dot-2 {
    animation: loader1 1s infinite;
  }

  .dot-3 {
    animation: loader2 1s infinite;
    margin-right: 0;
  }
}

@keyframes loader {
  25% {
    opacity:1;
    transform:scale(1.25);
  }
}

@keyframes loader1 {
  50%{
    opacity:1;
    transform:scale(1.25);
  }
}

@keyframes loader2{
  75%{
    opacity:1;
    transform:scale(1.25);
  }
}