.js-stickyheader-active {
  @media (min-width: break($_l)) {
    .sticky-header {
      visibility: visible;
      opacity: 1;
      z-index: 9999;
    }
  }
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background: $c-cream;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.02);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.sticky-header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1020px;
  height: 105px;
  margin: 0 auto;
  padding: 0 20px;
}

.sticky-header__list {
  display: flex;
  flex: 1;

  .flickity-viewport {
    width: 100%;
  }

}

.sticky-header__item {
  position: relative;
  padding: 0 15px;
  text-align: center;
  &:after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba($c-brown, 0.18);
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
  &.is-nav-selected {
    .sticky-header__item-title {
      color: $c-blue;
    }
  }
}

.sticky-header__item-title,
.sticky-header__item-content {
  display: block;
}

.sticky-header__item-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  transition: color 0.3s ease;
}

.sticky-header__button {
  margin-right: 30px;
}

.sticky-header__link {
  color: $c-blue;
  text-decoration: underline;
}