.carousel-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-controls__button {
  flex: 0 1 0;
  appearance: none;
  background: transparent;
  padding: 0;
  color: $c-blue;
  font-size: 15px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;

  @media (min-width: break($_m)) {
    font-size: 24px;
  }
}
.carousel-controls__indicator {
  margin: 0 12px 0 10px;
  color: $c-blue;
  font-size: 13px;
  line-height: 24px;

  @media (min-width: break($_m)) {
    font-size: 16px;
    margin: 0 22px 0 20px;
  }
}
