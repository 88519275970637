//Upcoming Dates
.upcoming-dates__item-wrapper {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	&.slick-initialized {
		display: block;
	}
	.slick-track {
		margin: 10px auto; //centering slides
	}
	//display: flex;
	//width: 100%;
	//justify-content: center;
	//align-items: center;
}


.upcoming-dates__item {
	&:not(.slick-slide) {
		padding: 10px 25px;
	}
	&:first-of-type {
		margin-left: 0;
	}
	&:last-of-type {
		&:after {
			content: none;
		}
	}
	@media(min-width:break($_s)){
		&:after {
			content:'';
			// @extend .o-divider-line-v;
			@include o-divider-line-v;
			height: 50px;
			width: 1px;
			// @extend .p-vc;
			@include p-vc;
			right: 0;
		}
	}
/* 	@media(min-width:break($_xl)){
		&.slick-slide {
			padding: 0;
		}
	} */
}

.upcoming-dates__callout {
	padding: 20px;
	margin-top: 20px;
	width: 100%;
	background: $c-cream;
	@media(min-width:break($_s)){
		// @extend .pv-25;
		@include pv-25;
		margin-left: auto;
		margin-right: auto;
	}
	@media(min-width:break($_ml)){
		padding: 20px 35px;
		max-width: em(720px);
		margin-top: 30px;
	}
	&.rte p {
		@extend .p;
	}
}

.slick-arrow {
	.upcoming-dates & {
		cursor: pointer;
		height: 100%;
		position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background: #fff;
  	&.slick-prev {
  		padding: 0 5px;
			left: 0;
			@media(min-width:break($_ml)){
				left: -25px;
				padding: 0 10px;
			}
			i {
		    right: 0;
			}
		}
		&.slick-next {
			padding: 0 5px;
			right: 0;
			@media(min-width:break($_ml)){
				right: -25px;
				padding: 0 10px 0 20px;
			}
			i {
		    left: 0;
			}
		}
		i {
			position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
		}
	}
	&.slick-disabled {
    cursor: default;
	}
}
.upcoming-date__missed--link {
	@extend %a;
}