//4-up Mosaic
/*  This could use some refactoring */

//Need this specific styling bc bg changes at xl breakpoint
section:not(.bg-c-cream)
+ .four-up-mosaic {
  margin-top: $section-spacing / 2;
  margin-bottom: $section-spacing / 2;
  @media(min-width:break($_s)) and (max-width:break($_xl,false)){
    margin-top: $section-spacing--lg / 2;
    margin-bottom: $section-spacing--lg / 2;
  }
  @media(min-width:break($_xl)) {
		margin-top: 0;
  	margin-bottom: 0;
	}
}

section.bg-c-cream
+ section:not(.bg-c-cream){
  margin-top: $section-spacing / 2;
  @media(min-width:break($_s)){
    margin-top: $section-spacing--lg / 2;
  }
}

.four-up-mosaic {
	background-color: $c-cream;
	&.section-padding {
		padding-top: $section-spacing;
    padding-bottom: $section-spacing;
		@media(min-width:break($_s)) and (max-width:break($_xl,false)){
		 	padding-top: $section-spacing--lg;
      padding-bottom: $section-spacing--lg;
		}
		@media(min-width:break($_xl)) {
			padding-top: $section-spacing--lg / 2;
    	padding-bottom: $section-spacing--lg / 2;
		}
	}
	@media(min-width:break($_xl)){
		background-color: $c-white;
	}
	//Specific Newsletter styling
	.newsletter {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-flow: row wrap;
		height: 100%;
	}
	.newsletter__text-wrapper {
		text-align: center;
		flex: 0 90%;
		color: $c-white;
		h6 {
			color: $c-white;
		}
		@media(min-width:break($_ml)){
			flex: 0 50%;
		}
	}
	.newsletter__form {
		flex: 0 100%;
		@media(min-width:break($_ml)){
			flex: 0 50%;
		}
	}
	.newsletter__input-group {
		@media(min-width:break($_ml)){
			width: 90%;
			margin-left: auto;
		}
	}
	.newsletter__submit {
		background-color: $c-white;
	}
	//Specific Square CTA styling - bigger breakpoint to switch to card
	.square-cta__item {
		float: none;

		> a {
			@media(max-width:break($_xl, false)){
				position: relative;
			}
		}

		&:before {
	    @media(max-width:break($_xl, false)){
		    content: "";
		    display: block;
		    padding-top: 0;
	  	}
		}

		.square-cta__item-img {
			@media(max-width:break($_xl, false)){
				padding-top: 56.25%;
				position: relative;
			}
		}

	 .square-cta__item-content {
	 		@media(max-width:break($_xl, false)){
				position: relative;
		    display: block;
		    width: 100%;
				margin: 0 auto;
		    height: 100%;
		    max-width: 80%;
		    padding: 20px 0;
		    .inner {
		      display: block;
		    }
			}
		}
		.square-cta__item-title {
	    &.show-xl-min {
	      margin: auto;
	    }
	    &.hide-xl-min {
	      max-width: 90%;
	      left: 0;
	      right: 0;
	      margin: auto;
	    }
	  }

		.square-cta__item-text {
			@media(max-width:break($_xl, false)){
				user-select: text;
		    position: relative;
		    transition: max-height 300ms cubic-bezier(.72, 0, .28, 1);
		    overflow: visible !important;
	    	p {
					&:not(.square-cta__item-link) {
			      position: relative;
			      padding-top: 0;
			      transition: opacity 300ms cubic-bezier(.72, 0, .28, 1);
			      @media(max-width:break($_xl)){
			      	color: $c-brown;
			      }
		    	}
	    	}
    	}
		}

		.square-cta__item-link {
			@media(max-width:break($_xl, false)){
				color: $c-blue;
				&:hover {
					color: $c-blue-dk;
				}
			}
		}
  	@include not-mobile($_xl) {
	    > a:not(:hover) {
	      .square-cta__item-text {
	        max-height: 0 !important;

	        p {
	          opacity: 0;
	        }
	      }
	    }
  	}
	}
}

.four-up-mosaic__inner {
	overflow: hidden;
	@extend .flex-grid;
	align-items: stretch;
	width: 100%;
	@media(min-width:break($_xl)){
		display: block;
	}
}

.four-up-mosaic__cta {
	width: 100%;
  background-color: $c-white;
	order: 2;
	margin-bottom: 20px;
	&:nth-child(2) {
			order: 3;
		}
	@media(min-width:break($_s)){
		width: 47.5%;
		margin-bottom: 20px;
		&:nth-child(2) {
			margin-bottom: 40px;
		}
	}
	@media(min-width:break($_ml)){
		width: 32.3%;
		margin-bottom: 40px;
		&:nth-child(2) {
			margin-right: 0;
		}
	}
	@media(min-width:break($_xl)){
		width: 32.3%;
		margin-bottom: 1.56%;
		&:nth-child(2) {
		margin-right: 1.54%;
		margin-bottom: 1.56%;
		}
	}
}

.four-up-mosaic__jpdi {
  	width: 100%;
    padding-top: 136%;
    order: 1;
    margin-bottom: 20px;
  @media(min-width:break($_s)){
    order: 1;
    width: 47.5%;
    background-color: $c-white;
    padding-top: 0;
  }
  @media(min-width:break($_ml)){
  	width: 32.2%;
    margin-bottom: 40px;
  }
	@media(min-width:break($_xl)){
		padding-top: 50.5%;
		width: 32.3%;
		margin-right: 1.54%;
		margin-bottom: 0;
		background-color: transparent;
	}

	.square-cta__item-link {
		transition: color $trans-duration ease-in-out;
		margin-top: 10px;
		color: $c-white;
			&:hover {
				color: $c-white;
			}
		@media(min-width:break($_s)) and (max-width:break($_xl, false)){
			color: $c-blue;
		&:hover {
			color: $c-blue-dk;
		}
		}
	}
}

.four-up-mosaic__jpdi-link:hover {
	.four-up-mosaic__jpdi-img:after {
		background-color: rgba(0,0,0,0.4);
	  transition: background-color $trans-duration ease-in-out;
	}
}

.four-up-mosaic__jpdi-img {
	background-size: cover;
	background-repeat: no-repeat;
	top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  &:before {
  	content: '';
  	position: absolute;
    top: 0;
    left: 0;
    right: 0;
		bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 100%);
  }
  &:after {
  	content: '';
  	position: absolute;
    top: 0;
    left: 0;
    right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0);
    transition: background-color $trans-duration ease-in-out;
  }
	@media(min-width:break($_s)) and (max-width:break($_xl, false)){
		padding-top: 56.25%;
		background-size: cover;
		background-position: 0 27%;
		position: relative;
	}
}

.four-up-mosaic__jpdi-title-wrapper {
	position: absolute;
	width: 100%;
  top: 15%;
  z-index: 1;
	transform: translateY(-50%);
	@media(min-width:break($_s)){
		top: 50%;
	}
	@media(min-width:break($_xl)){
  	top: 5%;
  	transform: none;
	}
}

.four-up-mosaic__jpdi-title {
  padding-top: 21%;
	@media(min-width:break($_s)){
  	padding-top: 26%;
	}
}

.four-up-mosaic__jpdi-content {
		position: absolute;
		bottom: 5%;
		width: 80%;
		right: 0;
		left: 0;
		color: $c-white;

  @media(min-width:break($_s)){
	  position: relative;
		max-width: 80%;
		padding: 20px 0;
		color: $c-text;
	}
  @media(min-width:break($_xl)){
		position: absolute;
		bottom: 6%;
		width: 80%;
		right: 0;
		left: 0;
		color: $c-white;
	}
	@media(min-width:break($_s)) and (max-width:break($_xl, false)){
		a{
			margin-top: 10px;
			color: $c-blue;
			display: block;
			&:hover {
				color: $c-blue-dk;
			}
		}
	}
}

.four-up-mosaic__newsletter {
	background-color: $c-blue;
	width: 100%;
  order: 4;
	@media(min-width:break($_xl)){
		transform: translateY(0);
		width: 66.1%;
		padding-top: 16.66666%;
	}
	@media (max-width: break($_ml)) and (min-width:break($_s)) {
		.newsletter {
			.newsletter__text {
				margin: 0 auto;
				max-width: 350px;
			}

			form {
				width: 90%;
			}
		}
	}
}

.four-up-mosaic__newsletter-inner {
	position: relative;
	padding: 20px;
	@media(min-width:break($_s)){
		padding: 40px;
	}
	@media(min-width:break($_ml)){
		padding: 50px;
	}
	@media(min-width:break($_xl)){
		position: absolute;
		// @extend .p-fill;
		@include p-fill;
		padding: 30px;
	}
	@media(min-width:break(1200px)){
		padding: 50px;
	}
}
