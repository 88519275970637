// Footer
.footer {
	overflow: hidden;
	border-top: 1px solid rgba($c-brown, .14);
	@media(min-width:break($_xl)){
	}
	.newsletter-text {
		margin-bottom: 20px;
	}
	&.section-padding  {
    padding-top: $section-spacing;
    padding-bottom: $section-spacing ;
    @media(min-width:break($_s)){
      padding-top: $section-spacing--lg;
    }
  }

  .page-template-page-calendar & {
    @media (max-width: break($_ml, false)) {
      margin-bottom: 70px;
    }
    @media (max-width: break($_ml, false)) {
      margin-bottom: 65px;
    }
  }
}

.footer__menu-wrapper {
	@media(min-width:break($_xl)){
			padding-left: 3em;
			border-left: 1px solid $c-border;
	}
}

.footer__menu {
	font-size: 0;
	> li {
		display: inline-block;
		vertical-align: top;
		width: 49.9%; // todo - figure out what is happening here.
		padding: 0 30px 30px 0;
		&:nth-last-child(-n+2) {
			padding-bottom: 0;
		}
		@media(min-width:break($_ml)){
			width: 24.9%;
			&:nth-child(n+4) {
				padding-bottom: 0;
			}
		}
		 a {
			font-size: 1.4rem;
			line-height: 1.2;
		}
		> a {
			@extend .f5;
			margin-bottom: 10px;
			font-size: 1.2rem;
			cursor: default;
			pointer-events: none;
		}
	}
	.sub-menu {
		@extend .list--reset;
		line-height: 1;
		li {
			margin: .5em 0;
			a:hover {
				color: $c-blue;
				transition: color $trans-duration ease-in-out;
			}
		}
	}
}

.footer__contact {
	&:after {
		content: '';
		@extend .o-divider-line-faded;
		position: relative;
		width: 100%;
		display: block;
		margin: 0 auto $section-spacing;
	 	@media(min-width:break($_s)){
    	margin-bottom: $section-spacing--lg;
  	}
	}
 @media(min-width:break($_xl)){
		padding-right: 3em;
		&:after {
			content: none;
		}
	}
}

.footer__social-wrapper {
	text-align: center;
	margin-bottom: 30px;
	@media(min-width:break($_xl)){
		text-align: left;
	}
}

.footer__meta {
 	list-style: none;
  > li {
    display: inline-block;
    margin: 0;
    position: relative;
    > a:hover {
			color: $c-blue;
			transition: color $trans-duration ease-in-out;
    }
  }
  span:not(.footer__phone) {
  	&:before {
      font-family: "jp-icons";
      content: '\e800';
      color: $c-brown;
      font-size: 2px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px
    }
  }
}

.footer__phone {
	padding-left: 5px;
}

.footer__info {
	margin-top: 35px;
	@media(min-width:break($_xl)){
		margin-top: 65px;
	}
}