//Global Header
$conditional-height: 50px;
$conditional-height--lg: 56px;
$nav-breakpoint: 1300px;

$infobar-height: 45px;
$infobar-height--lg: 47px;

$header-height: 60px;
$header-height--lg: 85px;
$header-breakpoint: 1240px;
$header-gutter: 20px;
$header-gutter--lg: 35px;

$site-gutter: 20px;
$site-gutter--lg: 40px;

$section-spacing: 30px;
$section-spacing--lg: 50px;

$slick-font-family: "jp-icons";
$slick-dot-character: "\e801";
$slick-active-dot-character: "\e800";
$slick-dot-size: 10px;
$slick-dot-color: $c-blue;
$slick-dot-color-active: $slick-dot-color;

$info-bar-breakpoint: $_m;
$three-six-breakpoint: $_ml;
