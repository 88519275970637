// LAYOUT ELEMENTS
.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
 	position: relative;
  margin-top: $header-height;
  @media(min-width:break($header-breakpoint)){
    margin-top: $header-height--lg;
  }

  // NOTE: -1px is to correct Slick slider rendering at certain widths
  &.main-content--has-conditional-nav {
    margin-top: ($header-height + $conditional-height - 1px);
    @media(min-width:break($header-breakpoint)){
      margin-top: ($header-height--lg + $conditional-height - 1px);
    }
    @media(min-width:break($nav-breakpoint)){
      margin-top: ($header-height--lg + $conditional-height--lg - 1px);
    }
  }

  &.main-content--has-infobar {
    margin-top: ($header-height + $infobar-height - 1px);
    @media(min-width:break($header-breakpoint)){
      margin-top: ($header-height--lg + $infobar-height--lg - 1px);
    }

    &.main-content--has-conditional-nav {
      margin-top: ($header-height + $infobar-height + $conditional-height - 1px);
      @media(min-width:break($header-breakpoint)){
        margin-top: ($header-height--lg + $infobar-height--lg + $conditional-height - 1px);
      }
      @media(min-width:break($nav-breakpoint)){
        margin-top: ($header-height--lg + $infobar-height--lg + $conditional-height--lg - 1px);
      }
    }
  }

  main {
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: 0%;

    @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
      flex-shrink: 0;
      flex-wrap: auto;
    }
    > section:last-of-type:not(.bg-c-cream) {
      padding-bottom: 20px;
      @media(min-width:break($_s)){
        padding-bottom: 60px;
      }
    }
  }
}

//SEARCH PAGE
.search__results-count {
  border-bottom: 1px solid $c-border;
}
