.post-search {
	padding: 1.5em 0;
	border-bottom: 1px solid $c-border;
}

.post-search__inner {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-flow: row wrap;
}

.post-search__title {
	font-weight: 700;
	font-size: 1.6rem;
	margin-bottom: .5em;
	line-height: 1.5;
	@media(min-width:break($_xs)){
		font-size: 1.8rem;
	}
}
 .post-search__content {
		flex: 1 60%;
		padding-right: 1em;
	@media(min-width:break($_s)){
		padding-right: 2em;
	}
}
.post-search__thumb {
	image-rendering: -webkit-optimize-contrast;
}

.search__nav-links {
	> li {
		display: inline-block;
		margin: 0 7px;
		&.active {
			background: $c-blue;
	    border-radius: 100%;
	    width: 22px;
	    height: 22px;
	    line-height: 22px;
	    color: #fff;
		}
		&.search__nav-prev {
			margin-right: 15px;
		}
		&.search__nav-next {
			margin-left: 15px;
		}
		> a {
			font-size: 1.4rem;
		}
	}
}