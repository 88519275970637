section.accordion {
  margin-bottom: 60px;
  &:before {
    display: none !important;
  }
}

.accordion__item {
  border-top: 1px solid $c-brown-lt;
  transition: padding-bottom 0.3s ease-in-out;

  &.js-accordion-active {
    padding-bottom: 35px;
  }
  &:last-child {
    border-bottom: 1px solid $c-brown-lt;
  }
}

.accordion__item-header {
  position: relative;
}

.accordion__item-title {
  display: block;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.2px;
  padding-top: 35px;
  padding-bottom: 35px;
  cursor: pointer;
  transition: padding-bottom 0.3s ease-in-out !important;

  .js-accordion-active & {
    padding-bottom: 25px;
  }
  .accordion__item--table.js-accordion-active > .accordion__item-header & {
    padding-bottom: 35px;
  }
}

.accordion__icon {
  position: absolute;
  right: 0;
  top: 42px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  pointer-events: none;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: $c-blue;
    position: absolute;
    top: 50%;
    left: 0;
  }
  &:after {
    transition: all 0.3s ease;
    content: '';
    width: 100%;
    height: 1px;
    background: $c-blue;
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(-90deg);
  }
  .js-accordion-active > &,
  .js-accordion-active > .accordion__item-header & {
    &:after {
      transform: rotate(0deg);
    }
  }
}

.accordion__tagline {
  margin: 0 0 15px 0;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 17px;
}

.accordion__intro {
  padding-bottom: 30px;
}

.accordion__item-content {
  display: none;
}

.accordion__body {
  display: flex;
  flex-flow: row wrap;
}

.accordion__image {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 30px;
  @media (min-width: break($_m)) {
    width: 325px;
    margin-right: 30px;
    margin-bottom: 0;
  }
  img {
    max-width: 100%;
  }
}

.accordion__description {
  flex: 0 1 auto;
  @media (min-width: break($_m)) {
    flex: 1;
  }
  a {
    @extend %a;
  }
}

.accordion--table-cell {
  margin-bottom: 0;

  &.comparison-table__cell {
    padding-top: 0;

    @media (min-width: break($_m)) {
      padding-top: 30px;
    }
  }

  .accordion__item {
    position: relative;
    border-top: none;

    @media (min-width: break($_m)) {
      border-bottom: none;
      padding-bottom: 0;

      &:after {
        content: 'accordion-disabled';
        display: none;
      }
    }

    &.js-accordion-active {
      padding-bottom: 20px;

      @media (min-width: break($_m)) {
        padding-bottom: 0;
      }
    }
  }
  .accordion__icon {
    top: 19px;
    
    @media (min-width: break($_m)) {
      display: none;
    }
  }
  .accordion__item-title {
    cursor: auto;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (min-width: break($_m)) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}