// FORMS
.input-group {
	@extend .relative;
	margin: 1em auto .66em;
}
label,
input,
select {
	@extend .relative;
}
input[type="text"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="password"],
select, {
	border: 1px solid $c-brown-lt;
	padding: .35em .65em;
	height: 40px;
}
label {
	color: #333333;
	margin-bottom: .25em;
}
// Adds a custom arrow
.select-wrapper {
	@extend .inline-relative;
	&:after {
		@extend .absolute;
		content: '\25bc';
		top: calc(50% - 0.5em);
		right: 1em;
		font-size: 2em;
		color: $c-theme;
		pointer-events: none;
	}
	&.sub-nav__wrapper {
		&:after {
			font-size: 1em;
		}
	}
	// .select-wrapper > select assumes you want to remove default styles
	select {
		padding: .35em 2.5em .35em .55em;
		appearance: none;
			-webkit-appearance: none;
	}
}

.select-wrapper--caret {
	&:after {
		@extend .absolute;
		@extend .u-bg-cn;
		content: '';
		width: 10px;
		height: 15px;
		top: calc(50% - 7.5px);
		right: 0.5em;
		background-image: url('../img/icon-caret.png');
	}
}

select {
	background-color: white;
	border-radius: 0;
}
textarea {
	border: 1px solid #999999;
	min-height: 100px;
	padding: .55em;
	width: 100%;
}
input[type="checkbox"],
input[type="radio"] {
	border: 1px solid #999999;
	vertical-align: middle;

	&:checked{
	  background-color: #148ECC;
	  border-color: #148ECC;
	}
	&:focus{
	  box-shadow: none;
	}
}
label.checkbox, label.radio {
	input {
		font-size: 2rem;
		margin: 0 .33em 0 0;
		vertical-align: middle;
	}
}
input[type="radio"] {
	border-radius: 100%;
}

.checkbox-wrapper {
	display: inline-block;
	position: relative;
	width: 12px;
	height: 12px;
	border: 1px solid $c-brown;

	.checkbox-fill {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 8px;
		height: 8px;
		margin: auto;
		background: $c-blue;
		opacity: 0;
		transition: opacity 150ms;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 12px;
		height: 12px;
		opacity: 0;

		&:checked + .checkbox-fill {
			opacity: 1;
		}
	}
}

.radio-wrapper {
	display: inline-block;
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	border: 1px solid $c-brown;

	.radio-fill {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 6px;
		height: 6px;
		border-radius: 100%;
		margin: auto;
		background: $c-blue;
		opacity: 0;
		transition: opacity 150ms;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		opacity: 0;

		&:checked + .radio-fill {
			opacity: 1;
		}
	}
}