//2-up Video
.two-up-video {
	+ section:not(.bg-c-cream):not(.two-up-image) {
		&:before {
			content: '';
			@extend .o-divider-line-faded;
			position: relative;
			width: 100%;
			display: block;
			margin: 0 auto $section-spacing;
			@media(min-width:break($_s)){
				margin-bottom: $section-spacing--lg;
			}
		}
	}
}

.two-up-video__inner {
	align-items: flex-start;
	@media(min-width:break($_xl)){
		align-items: center;
	}
	@media(min-width:break(639px)){
		.two-up-video--alt & {
			flex-flow: row-reverse;
		}
	}
}

.two-up-video__img-wrapper {
	width: 100%;
	@media(min-width:break(639px)){
		width: 49%;
	}
	@media(min-width:break($_xl)){
		width: 66%;
	}
}

.two-up-video__content {
	width: 100%;
	margin-top: 20px;

	a:not(.button--primary) {
		color: $c-blue;
		text-decoration: underline;

		&:hover {
			color: $c-blue-dk;
		}
	}
	@media(min-width:break(639px)){
		width: 46%;
		margin-top: 0;
	}
	@media(min-width:break($_xl)){
		width: 29%;
	}
}

.two-up-video__link {
	.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0, 0);
    transition: background $trans-duration ease-in-out;
	}

	&:hover {
		.overlay {
	    background: rgba(0,0,0, .25);
		}
	}
}

.two-up-video__button-wrapper {
	@media(max-width:break(638px)){
		text-align: center;
		.button--primary {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.video__thumbnail {
	&:before {
		content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 85px;
    height: 85px;
    line-height: 100px;
    border-radius: 50%;
    margin: auto;
    background-color: rgba($c-blue, .7);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transform: translateZ(0);
    transition: 600ms cubic-bezier(0.28, 0, 0.15, 1);
    z-index: 1;
    @media(min-width:break(639px)){
		 	width: 100px;
  		height: 100px;
		}
		@media(min-width:break($_xl)){
		 	width: 125px;
  		height: 125px;
		}
	}
	&:hover {
		&:before {
			transform: translateZ(0) scale(1.15);
		}
	}
}


.video__icon-text {
	z-index: 2;

	&:after, &:before {
		color: $c-white;
		max-width: 50px;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
		transform: translateY(-50%);
		@extend .f5;
		z-index: 1;
		transition: opacity $trans-duration ease-in-out;
	}
	&:after {
		content: 'Watch Video';
		opacity: 1;
		.video__thumbnail:hover & {
			opacity: 0;

		}
	}
	&:before {
		content: '\e80c';
		font-family: 'jp-icons';
		font-size: 40px;
		opacity: 0;
		left: 15px;
		.video__thumbnail:hover & {
			opacity: 1;
		}
	}
}
