.schedule-list {
  &:before {
    display: none !important;
  }
}

.schedule-list__inner {
  @extend .post__inner;
}
.schedule-list__title {
  @extend %schedule--sm-title;
  margin-bottom: 15px;
  position: relative;
  @media (min-width: break($_s)) {
    margin-bottom: 20px;
  }
}
.schedule-item__body {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;

  .schedule-item__active & {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}
