.p-tl{
  top:0;
  left: 0;
}
.p-bm{
  bottom:0;
  left:0;
  right:0;
  margin:auto;
}
.p-fill{
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
}
@mixin p-fill {
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
}
.p-tbr{
  top:0;
  bottom:0;
  right:0;
}
.offset-t50{
  top:50%;
}

.p-vc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}
@mixin p-vc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}