//2-up Overlap
.two-up-overlap__inner {
	overflow: hidden;
}

.two-up-overlap__title-wrapper {	
	max-width: 275px;
	margin: 0 auto;
	@media(min-width:break($_s)){
		max-width: 300px;
	}
	@media(min-width:break($_xl)){
		max-width: 400px;
	}
}

.two-up-overlap__title {
	//margin-left: 15px;
	line-height: 1.6;
}

.two-up-overlap__title-in-dance {
	margin-right: 20%;
}

.two-up-overlap__left {
	@media(min-width:break($_ml)){
		margin-top: 75px;
	}
}

.two-up-overlap__right {
	padding-left: 17%;
	margin: 5% 0 0 0;
	@media(min-width:break($_ml)){
		margin: 0;
	  padding: 0 0 10% 0;
	}
	@media(min-width:break(1200px)){
		padding: 0 0 15% 0;
	}
}

.two-up-overlap__quote {
		.byline {
			@extend .f5;
			text-align: center;
			margin-bottom: 0;
			margin-top: 20px;
		}

		.affiliation {
			@extend .small;
			text-align: center;
			font-style: italic;
		}
		@media(min-width:break($_s)){
			max-width: 70%;
		}
	}

.two-up-overlap__right-bg {
 	top: 15%;
	height: 100%;
 	z-index: -1;
	@media(min-width:break($_ml)){
	 height: 75%;
	}
	@media(min-width:break(1200px)){
		height: 70%;
	}
}

.two-up-overlap__excerpt {

	a:not(.button--primary) {
		color: $c-blue;
		text-decoration: underline;

		&:hover {
			color: $c-blue-dk;
		}
	}
 	@media(min-width:break($_s)){
 		max-width: 75%;
 	}
}

.two-up-overlap__top-image-wrapper {
	max-width: em(495px);
	margin: 0 auto;
}

.two-up-overlap__top-image {
	padding-top: 85.6%;
}

.two-up-overlap__bottom {
  padding-top: 56.25%;
  @media(min-width:break($_ml)){
  width: 55%;
	padding-top: 1%
	}
}

.two-up-overlap__bottom-img {
	position: absolute;
    height: 100%;
    width: 80%;
    top: 0;
    bottom: 0;
    left: 0;
	@media(min-width:break($_ml)){
		transform: translateY(-100%);
		padding-top: 56.25%;
		width: 100%;
	}
}

.two-up-overlap__bottom-caption {
	margin-top: 5px;
	@media(min-width:break($_ml)){
		margin-top: 0;
		text-align: center;
	}
}