.six-up__grid {
  @extend .flex-grid;
  justify-content: center;
}
.six-up__headline {
  @extend .f2;
  text-align: center;
  margin-bottom: 25px;
}
.six-up__description {
  margin: 0 auto 30px;
  max-width: 800px;
  text-align: center;
}
.six-up__grid {
  margin-left: -10px;
  margin-right: -10px;

  @media (min-width: break($_m)) {
    margin-left: -9px;
    margin-right: -9px;
  }
}
.six-up__card {
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;

  @media (min-width: break($_m)) {
    padding-left: 9px;
    padding-right: 9px;
    width: 25%;
  }

  &:first-child,
  &:nth-child(2) {
    width: 100%;

    @media (min-width: break($_m)) {
      width: 50%;
      margin-bottom: 20px;
    }
    .card__headline {
      @extend .f4;
    }
    .card__content {
      margin-top: -27px;
      padding-bottom: 85px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .card__link {
      display: none;
    }
    .card__link-text {
      a {
        @extend .button;
        @extend .button--primary;
        display: inline-block;
        width: auto;
        max-width: none;
      }
    }
  }
  &:first-child {
    @media (min-width: break($_m)) {
      padding-right: 11px;
    }
  }
  &:last-child {
    @media (min-width: break($_m)) {
      padding-left: 11px;
    }
  }

  .card__link-text {
    width: auto;
    left: 0;
    right: 0;

  }
}
