//Newsletter form
.newsletter {
  .footer & {
    text-align: center;
    @media(min-width:break($_xl)){
      text-align: left;
    }
  }
}

.newsletter__form {
  .footer & {
    max-width: 300px;
    margin: 0 auto;
    @media(min-width:break($_xl)){
      margin: 0;
    }
  }
}

.footer__contact {
  .newsletter__form {
    .newsletter__input-group.active {
      border-color: $c-blue;
    }
  }
}
.four-up-mosaic__newsletter-inner {
  .newsletter__form {
    .newsletter__input-group.active {
      box-shadow: 0 0 2em 0.25em rgba(0,0,0,.35);
    }
  }
}
.newsletter__text-wrapper {
  margin-bottom: 10px;
  .footer & {
      span {
        display: block;
      }
   @media(min-width:break($_xl)){
      margin-bottom: 0;
      span {
        display: inline;
      }
    }
  }
}

.newsletter__text {
  .four-up-mosaic & {
    @media(max-width:break($_ml, false)){
      margin-bottom: 10px;
    }
  }
  .footer & {
     margin-bottom: 10px;
  }
}

.newsletter__input-group {
  transition: all 0.3s;
  width:100%;
  display: flex;
  border: 1px solid $c-border;
  input:first-child {
  width: 99%; //fixes overflow on from submit button
  }
  input[type="email"] {
    border: none;
    outline:0;
  }
}

.newsletter__submit {
	background-color: transparent;
  cursor: pointer;
	margin: 0;
 	outline:0;
  border: none;
 	-webkit-appearance: none;
  padding: .4em 1em;
  width: auto;
  flex: 1 0 auto;
}