// TYPOGRAPHY
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,800italic,400,300,800);
$font-stack__sans: "futura-pt", Helvetica, Arial, sans-serif;
$font-stack__serif: georgia, serif;
$font-stack__body: $font-stack__sans;
$font-stack__headings: $font-stack__sans;

html, body {
	color: $c-brown;
	font-family: $font-stack__body;
	font-size: 62.5%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
	font-size: 1.6rem;
}
.font-sans {
	font-family: $font-stack__sans;
}
.font-serif {
	font-family: $font-stack__serif;
}
.font-monospace {
	font-family: Consolas , Monaco, monospace;
}
h1, h2, h3, h4, h5, h6, p {
	margin: 0;
}
h1, h2, h3, h4, h5, h6 {
	font-family: $font-stack__headings;
	color: $c-brown;
}
h1,
.f1 {
	font-size: 2.1rem;
  letter-spacing: .25em;
  line-height: 1.1;
  font-weight: 700;
  text-transform: uppercase;
	@media screen and (min-width: break($_s)) {
		font-size: 3.4rem;
		letter-spacing: .175em;
	}
	@media screen and (min-width: break($_xl)) {
		font-size: 4.4rem;
	}
}
h2,
.f2 {
	font-size: 2.1rem;
  letter-spacing: .2em;
  font-weight: 700;
  text-transform: uppercase;
	@media screen and (min-width: break($_s)) {
		font-size: 2.4rem;
	}
	@media screen and (min-width: break($_xl)) {
		font-size: 2.9rem;
	}
}
h3,
.f3 {
	font-size: 1.6rem;
  font-weight: 700;
	@media screen and (min-width: break($_s)) {
		font-size: 2.1rem;
	}
	@media screen and (min-width: break($_xl)) {
		font-size: 2.5rem;
	}
}
h4,
.f4 {
	font-size: 1.4rem;
  letter-spacing: .2em;
  font-weight: 700;
  text-transform: uppercase;
  @media screen and (min-width: break($_s)) {
		font-size: 1.6rem;
	 	letter-spacing: .25em;
	}
  @media screen and (min-width: break($_xl)) {
		font-size: 2.1rem;
	 	letter-spacing: .2em;
	}
}
h5,
.f5 {
	font-size: 1.2rem;
  letter-spacing: .15em;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  @media screen and (min-width: break($_s)) {
		font-size: 1.2rem;
	 	letter-spacing: .175em;
	}
}
@mixin f5 {
	font-size: 1.2rem;
  letter-spacing: 1.6px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  @media screen and (min-width: break($_s)) {
		font-size: 1.2rem;
	 	letter-spacing: .175em;
	}
}
h6,
.f6 {
	font-size: 1.4rem;
  letter-spacing: .025em;
  line-height: 1.5;
  font-weight: 700;
   @media screen and (min-width: break($_s)) {
		font-size: 1.6rem;
	}
}
.f7 {
	font-size: 1.6rem;
	letter-spacing: .32em;
	line-height: 1.778;
	font-weight: 700;
	text-transform: uppercase;
	@media screen and (min-width: break($_s)) {
		font-size: 1.8rem;
	}
}
.f8 {
	font-size: 1.3rem;
	letter-spacing: .32em;
	line-height: 1.5;
	font-weight: 700;
	text-transform: uppercase;
	@media screen and (min-width: break($_s)) {
		font-size: 1.5rem;
	}
}
.f8--support {
	font-size: 1.5rem;
	letter-spacing: 0.2em;
	line-height: 1.6;
}

%schedule--sm-title {
	font-size: 1.2rem;
	line-height: 1.4;
	letter-spacing: .03em;
	font-weight: 700;
	@media screen and (min-width: break($_s)) {
		font-size: 1.5rem;
		line-height: 1.6;
	}
}
%schedule-title {
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.31px;
	font-weight: 700;
	@media screen and (min-width: break($_s)) {
		font-size: 17px;
		line-height: 21px;
	}
}

p, ul, ol, figcaption,
.p {
	font-size: 1.5rem;
  line-height: 1.7;
  font-weight: 400;
   @media screen and (min-width: break($_s)) {
   	font-size: 1.6rem;
	}
}

hr {
	@extend .block-relative;
	background-color: rgba($c-brown, .14);
	border: 0;
	height: 1px;
	margin: 30px 0;
}
a {
	text-decoration: none;
}

.small {
	font-size: 1.3rem;
}

%p {
	margin-bottom: 25px;
	&:last-child {
		margin-bottom: 0;
	}
}

%a {
	text-decoration: underline;
	color: $c-blue;
	transition: color $trans-duration;
	&:hover, &:focus {
		color: $c-blue-dk;
	}
}

%caption {
	font-size: 1.3rem;
	font-style: italic;
	font-weight: 400;
}

%content {
	@extend .p;
	letter-spacing: 0.025em;
}
%content-large {
	font-size: 2rem;
	line-height: 1.32;
	font-weight: 400;
	letter-spacing: 0.03em;
	@media screen and (min-width: break($_s)) {
		font-size: 2.2rem;
	}
}

.secondary-sub-heading {
	margin-bottom: 5px;
}

//Custom styling for WP editor content
.rte {
/*   h1, h2, h3, h4, h5, h6,
  .f1, .f2, .f3, .f4, .f5, .f6 {
    margin: 1ex 0 .6ex;
  } */
  p {
  	@extend %p;
  	overflow-wrap: break-word;
  	word-wrap: break-word;
  	hyphens: manual;
  }

  a {
  	@extend %a;
  }

  > ul, > ol {
    margin-bottom: 25px;
    padding-left: 1em;
    ul, ol {
    	margin-bottom: 1em;
    }
  }

  li {
		margin: .75em 0;
	  	list-style: disc;
	  	margin-left: 42px;
  }

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 0.5em;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.intro-text {
		font-weight: 400;
	}

	.sub-heading {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1.3;
		margin-bottom: 5px;
		@media screen and (min-width: break($_s)) {
	   		font-size: 1.6rem;
		}
		/* span {
			border-bottom: 2px solid #F3F2F0;
		} */
	}

	.button {
		a {
			color: $c-white;
			text-decoration: none;
		}
	}

	blockquote {
		margin: 3em 0;
		text-align: center;
		p:not(.byline):not(.affiliation) {
			@extend .f4;
			@extend .c-blue;
		}
		&:before, &:after {
			content: '';
			@extend .o-divider-line-faded;
			position: relative;
			width: 30%;
			display: block;
			margin: 0 auto;
		}
		&:before {
			margin-bottom: 40px;
		}
		&:after {
			margin-top: 40px;
		}
	}

	.byline {
		@extend .f5;
		text-align: center;
		margin-bottom: 0;
		margin-top: 20px;
	}

	.affiliation {
		@extend .small;
		text-align: center;
		font-style: italic;
	}

	figure {
		position: relative;
		margin-bottom: 20px;
		figcaption {
	  	text-align: center;
      position: relative;
	    margin: 0;
	    padding: 5px 8px;
	    background: $c-cream;
	    @media(max-width:break($_s, false)){
		    top: -4px; //not sure why this is not flush to bottom of img
		  }
	    @media(min-width:break($_s)){
			 	position: absolute;
		    right: -10px;
		    bottom: -10px;
	    }
		}
 	@media screen and (min-width: break($_s)) {
	 	margin-bottom: 45px;
	 }
	}
  @media screen and (min-width: break($_s)) {
		p, ul, ol {
			font-size: 1.75rem;
			line-height: 1.5;
		}
	}
}

// STYLES
// Pare these down based on your available fonts
.font-reset {
	font-size: 0;
}
.font-0 {
  margin: 0;
  line-height: 1;
}
.font-s-i {
	font-style: italic;
}
.font-w-2 {
	font-weight: 200;
}
.font-w-4 {
	font-weight: 400;
}
.font-w-5 {
	font-weight: 500;
}
.font-w-7 {
	font-weight: 700;
}
.font-w-8 {
	font-weight: 800;
}
.font-t-u {
	text-transform: uppercase;
}
.font-d-u {
	text-decoration: underline;
}
.typeset{
  max-width:27em;
}
.typeset--xs{
  max-width:18em;
}
.typeset--lg{
  max-width:34em;
}
.typeset--xlg{
  max-width:52em;
}

//Typekit loading
.wf-loading {
	h1,h2,h3,h4,h5,h6,p,a,ul,ol, span {
		opacity: 0;
		transition: opacity $trans-duration ease-in-out;
	}
}
.wf-active {
	h1,h2,h3,h4,h5,h6,p,ul,ol, span {
		opacity: 1;
		transition: opacity $trans-duration ease-in-out;
	}
	a {
		opacity: 1;
		transition: opacity $trans-duration ease-in-out, color $trans-duration ease-in-out, background-color $trans-duration, ;
	}
}

.content {
	&__paragraph {
    margin-bottom: 15px;
    @extend .rte;
    p {
      @extend %content;
    }
  }
}

.content__paragraph--large,
.content__paragraph--large p {
	@extend %content-large;
}

%picks-category-headline {
	font-family: $font-stack__headings;
	color: $c-brown;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.46;
	letter-spacing: 0.25px;
	text-transform: none;

	@media screen and (min-width: break($_s)) {
		font-size: 2.2rem;
		line-height: 1.3636;
		letter-spacing: 0.2px;
	}
}

%picks-more-link {
	@extend %a;
	font-size: 1.2rem;
	line-height: 1.5;

	@media (min-width: break($_s)) {
		font-size: 1.5rem;
		line-height: 1.46;
	}
}

%typo-quote-carousel__position {
	font-size: 1.2rem;
	line-height: 1.5;

	@media (min-width: break($_s)) {
		font-size: 1.5rem;
		line-height: 1.46;
	}
}