// GLOBAL
html, body{
  width:100%;
  height:100%;
}

.section-margin{
  margin-top: $section-spacing / 2;
  margin-bottom: $section-spacing / 2;
  @media(min-width:break($_s)){
    margin-top: $section-spacing--lg / 2;
    margin-bottom: $section-spacing--lg / 2;
  }
}
.section-padding{
  padding-top: $section-spacing / 2;
  padding-bottom: $section-spacing / 2;
  @media(min-width:break($_s)){
    padding-top: $section-spacing--lg / 2;
    padding-bottom: $section-spacing--lg / 2;
  }
  &.bg-c-cream, &.media-gallery, &.home-hero__sidebar {
    padding-top: $section-spacing;
    padding-bottom: $section-spacing;
    @media(min-width:break($_s)){
      padding-top: $section-spacing--lg;
      padding-bottom: $section-spacing--lg;
    }
  }
  &.three-six-grid {
    padding-top: $section-spacing;
    padding-bottom: $section-spacing;
    @media(min-width:break($_s)) and (max-width:break($_ml, false)){
      padding-top: $section-spacing--lg;
      padding-bottom: $section-spacing--lg;
    }
  }
}

section:not(.bg-c-cream):not(.home-hero):not(.landing-hero):not(.home-hero-yr):not(.event-hero)
  + section.bg-c-cream {
    margin-top: $section-spacing / 2;
    //margin-bottom: $section-spacing / 2;
    @media(min-width:break($_s)){
      margin-top: $section-spacing--lg / 2;
      //margin-bottom: $section-spacing--lg / 2;
    }
  }

section.bg-c-cream
+ section:not(.bg-c-cream){
  margin-top: $section-spacing / 2;
  @media(min-width:break($_s)){
    margin-top: $section-spacing--lg / 2;
  }
}

//divider lines on pages except home page
body:not(.home) {
  section:not(.bg-c-cream):not(.home-hero):not(.landing-hero):not(.home-hero-yr):not(.event-hero) {
    + section:not(.bg-c-cream){
      &:before {
        content: '';
        @extend .o-divider-line-faded;
        position: relative;
        width: 100%;
        display: block;
        margin: 0 auto $section-spacing;
        @media(min-width:break($_s)){
          margin-bottom: $section-spacing--lg;
        }
      }
    }
    @media(max-width:break($_ml, false)){
    + section.three-six-grid {
        &:before {
          content: none;
        }
      }
    }
  }
  section.landing-hero {
    @media(max-width:break($_ml, false)){
      + section:not(.bg-c-cream){
      &:before {
        content: '';
        @include o-divider-line-faded;
        position: relative;
        width: 100%;
        display: block;
        margin: 0 auto $section-spacing;
        @media(min-width:break($_s)){
          margin-bottom: $section-spacing--lg;
        }
      }
    }
    }
  }
}


//divider between 2up image/video that shows up on on tablet size down
section.two-up-image + section.two-up-video, section.two-up-video + section.two-up-image {
  &:before {
    content: '';
    @include o-divider-line-faded;
    position: relative;
    width: 100%;
    display: block;
    margin: 0 auto $section-spacing;
    @media(min-width:break($_s)){
      margin-bottom: $section-spacing--lg;
    }
  }
  @media(min-width:break($_xl)){
    &:before {
      content: none;
    }
  }
}

.section-padding-bottom{
  padding-bottom: $section-spacing / 2;
  @media(min-width:break($_s)){
    padding-bottom: $section-spacing--lg / 2;
  }
}

.section-padding-top{
  padding-top: $section-spacing / 2;
  @media(min-width:break($_s)){
    padding-top: $section-spacing--lg / 2;
  }
}

.section-title {
  margin-bottom: 20px;
}

.section-margin-bottom {
  margin-bottom: $section-spacing / 2;
  @media(min-width:break($_ml)){
    margin-bottom: $section-spacing--lg / 2;
  }
}
.content {
  p {
    margin-top: 20px;
    a {
      color: $c-blue;
      text-decoration: underline;
    }
  }
  .button {
    display: inline-block;

    a {
      color: $c-white;
      text-decoration: none;
    }
  }
}

.responsive-media {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 20px;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Page Thank you + Scheduled

.page-scheduled__section,
.page-thankyou__section {
  margin-bottom: 50px;
}

.page-scheduled__section {
  text-align: center;
}

.page-scheduled__title,
.page-thankyou__title {
  @extend h2;
  text-align: center;
}
