.sub-nav {
	display: none;
	max-width: 850px;
	margin: 0 auto;

	@media(min-width:break($_s)){
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		padding: 13px 0;
		> li {
			margin: 0;
			&.current_page_item {
				> a {
					color: $c-blue;
					&:after {
						opacity: 1;
					}
				}
			}
			> a {
				display: inline-block;
				margin: .75em 1em; //so hover line doesn't extend
				// @extend .f5;
				// @extend .c-hover-blue-dk;
				@include f5;
				@include c-hover-blue-dk;
				position: relative;
				&:hover, &:focus {
					color: $c-blue;
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
	@media(min-width:break($_ml)){
		padding: 13px 0;
	}
}

.sub-nav__wrapper {
	display: block;
	width: 100%;
	@media(min-width:break($_s)){
		display: none;
	}
}

.sub-nav__mobile {
	width: 100%;
	&:focus {
		outline: none;
	}
}