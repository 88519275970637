// DISPLAY & POSITIONING
.block {
	display: block;
}
.inline {
	display: inline;
}
.inline-block {
	display: inline-block;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.static {
	position: static;
}
.block-relative {
	@extend .block;
	@extend .relative;
}
.inline-relative {
	@extend .inline-block;
	@extend .relative;
}
.block-fixed {
	@extend .block;
	@extend .fixed;
}
.block-absolute {
	@extend .block;
	@extend .absolute;
}

.flex {
	display: flex;
}

.flex-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between; 
}

.flex-grid-center {
	display: flex;
	flex-flow: row wrap;
	justify-content: center; 
}

.flex-row {
	display: flex;
	justify-content: space-between;
}

.hidden {
	display: none;
}