//Info Bar

.info-bar {
	display: none;
	z-index: 12000;
	width: 100%;
	text-align: center;
	line-height: 20px;
	@extend .pv-10;
}

.info-bar__link {
	color: $c-white;
}

.info-bar__icon {
	font-size: 1rem;
}

.info-bar__container {
	height: 100%;
	position: relative;
	padding-right: 15px;
	@media (min-width: break($header-breakpoint)) {
		max-width: none;
	}
}
.info-bar__headline {
	@media (min-width: break($header-breakpoint)) {
		display: inline-block;
	}
}
.info-bar__close {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	//transform: translateY(-50%);
	cursor: pointer;
	z-index: 2;
	@media (min-width: break($_s)) {
		width: 50px;
	}

	&:after,
	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 13px;
		height: 1px;
		background-color: $c-white;
		opacity: .4;
		top: 50%;
		left: 0;
    right: 0;
    margin: auto;

	}
	&:after {
		transform: rotate(45deg);
	}
	&:before {
		transform: rotate(-45deg);
	}
	&:hover {
		&:after,
		&:before {
			opacity: 1;
		}
	}
}
