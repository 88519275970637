.three-up-cta {
  @media(min-width:break($_m)) {
    padding-bottom: 50px;
  }
}

.three-up-cta__inner {
  @media(min-width:break($_m)) {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px;
    padding-top: 20px;
  }
}

.three-up-cta__item {
  margin-bottom: 40px;
  text-align: center;
  &:last-child {
    margin-bottom: 0;
  }
  @media(min-width:break($_m)) {
    width: percentage(1/3);
    margin-bottom: 0;
    padding: 0 10px;
  }
}

.three-up-cta__link {
  display: block;
  margin-bottom: 20px;
}

.three-up-cta__image {
  @include aspect(262, 187);
  @extend .u-bg-cv;
  width: 100%;
  @media(min-width:break($_m)) {
    @include aspect(413, 231);
  }
}

.three-up-cta__description {
  @media(min-width:break($_m)) {
    max-width: 320px;
    margin: 0 auto;
  }
}

.three-up-cta__button {
  @extend .button--primary;
  margin: 15px auto 0;
  @media(max-width:break($_m, false)) {
    width: auto;
    max-width: inherit;
    display: inline-table;
    padding-left: 30px;
    padding-right: 30px;
  }
}