%title-hero {
  font-size: 21px;
  line-height: 35px;
  letter-spacing: 4px;
  font-weight: 700;
  @media(min-width:break($_m)){
    font-size: 40px;
    line-height: 54px;
    letter-spacing: 8px;
  }
} 

.title-hero {
  margin: 0 0 20px 0;
  @media(min-width:break($_m)){
    margin: 0 0 45px 0;
  }
  .tax-pick_category & {
    margin-bottom: 0;
  }
}

.title-hero__background{
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:before {
    content: '';
    padding-top: 26%;
    display: block;
    @media(min-width:break($_m)){
      padding-top: 19.445%;
    }
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $c-black;
    opacity: 0.2;
    z-index: 1;
  }
}

.title-hero__wrapper{
  position: relative;
}

.title-hero__content{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  bottom: 0;
  margin: auto;
  z-index: 2;
  color: $c-white;
}

.title-hero__description {
  @extend .hero__excerpt;
}

.title-hero__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 15px 60px;
  height: 100%;
  @media(min-width:break($_m)){
    max-width: 70%;
    padding: 0;
  }
}

.title-hero__title{
  @extend %title-hero;
  color: $c-white;
}

.title-hero__buttons {
  @extend .hero__button-wrapper;
}
.title-hero__button{
  margin: 15px 5px 0 5px;
  display: none;

  @media(min-width:break($_m)){
    display: inline-block;
  }
}

.title-hero__subtitle {
  font-size: 1.1rem;
  line-height: 1.54;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}