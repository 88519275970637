.media-gallery--support {
  background: $c-white;

  &:before {
    margin-bottom: 40px;
  }

  * {
    color: $c-brown;
  }
}
.media-gallery__slide-figure {
  overflow: hidden;
}
.media-gallery__headline {
  @extend .f2;
  span {
    display: block;

    &:nth-child(3n+2) {
      text-align: right;
      padding-right: 30px;

      @media (min-width: break($_m)) {
        padding-right: 0;
      }
    }
    &:nth-child(3n+3) {
      padding-left: 35px;
    }
  }
}
.media-gallery__intro {
  margin-top: 10px;
  text-align: center;

  p {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .button {
    display: inline-block;
  }
}
.media-gallery--with-intro {
  .media-gallery__headline {
    @extend .f7;
    margin: 0 auto;
    text-align: center;

    span {
      text-align: center;
      padding: 0;
    }
  }
  .media-gallery__pager {
    text-align: center;

    &,
    * {
      color: $c-blue;
    }
  }
}
