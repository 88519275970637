[data-src],
[data-bg-lazy]{
  opacity: 0;
  transition: opacity $trans-duration;

  &.visible{opacity: 1}
}
.img{
  width:100%;
  height:auto;
}
