%show {
  visibility: visible;
  opacity: 1;
}

%hide {
  visibility: hidden;
  opacity: 0;
}

// School Navigation
.conditional-nav {
  height: $conditional-height--lg;
  background-color: $c-blue;
  color: $c-white;
  @media(max-width:break($nav-breakpoint)) {
    height: $conditional-height;
    padding: 0 20px;
  }
  &.js-menu-open {
    @media(max-width:break($nav-breakpoint)) {
      .conditional-nav__menu {
        visibility: visible;
        opacity: 1;
        display: flex;
        position: absolute;
        top: 50px;
        right: 0;
        height: auto;
        background: $c-blue-dk;
        padding: 10px 20px;
        z-index: 10;
      }
      .conditional-nav__dropdown-icon {
        transform: rotate(-180deg);
      }
    }
  }
}

.conditional-nav--main {
  .conditional-nav__sub-headline {
    @media (min-width: $_m) {
      display: none;
    }
  }
}

.conditional-nav__wrapper	{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.conditional-nav__headline {
  @extend .f7;
  padding-left: 15px;
  @media (max-width: $_m) {
    display: none;
  }
}
.conditional-nav__sub-headline {
  @extend .f8;
  margin-right: auto;
  @media (min-width: $_m) {
    padding-left: 15px;
    &:before {
      content: '';
      height: 25px;
      border-left: 1px solid lighten($c-white, 0.63);
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle;
      transform: translateY(-3px);
    }
  }
}
.conditional-nav__menu {
  @extend .nav__quick-links;
  display: flex;
  flex-flow: column;
  list-style: none;
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all 0.3s ease-in-out;
  @media(min-width:break($nav-breakpoint)) {
    flex-flow: row;
    margin-left: auto;
    visibility: visible;
    opacity: 1;
    height: auto;
  }
  .menu-item {
    a {
      padding: 0 0 16px 0;
      color: rgba($c-white, 0.6);
      transition: all 0.3s ease-in-out;
      @media(min-width:break($nav-breakpoint)) {
        padding: 0 16px;
      }
    }
    &:hover,
    &.pre-selected {
      a {
        color: $c-white;
      }
    }
  }
}

.conditional-nav__dropdown-icon {
  flex: 0 0 14px;
  max-width: 14px;
  padding: 0;
  background: transparent;
  border: 0;
  text-align: center;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
  @media(min-width:break($nav-breakpoint)){
    display: none;
  }
}

.icon-caret {
  position: relative;
  width: 14px;
  height: 7px;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 7px solid $c-white;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 0;
    border-top: 4px solid $c-blue;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
}
