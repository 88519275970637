// GRID COMPONENTS
.container {
	@extend .block-relative;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}
.max-width{
  max-width: 1280px;
}
.max-width--xs{
  max-width:750px;
}
.max-width--sm{
  max-width:800px;
}
.max-width--md{
  max-width:1060px;
}
.max-width--lg{
  max-width:1440px;
}
.max-width--xl{
  max-width:1600px;
}
.max-width--700 {
	max-width: 700px;
}
.max-width--850{
	max-width: 850px;
}
.site-gutter {
	padding-left: $site-gutter;
	padding-right: $site-gutter;
	@media(min-width:break($_m)){
		padding-left: $site-gutter--lg;
		padding-right: $site-gutter--lg;
	}
}
