// BUTTONS
.button,
button {
	@extend .f5;
	border: 0;
	cursor: pointer;
	padding: .8em 2.2em;
	text-decoration: none;
	text-align: center;
	-webkit-appearance: none;
	@media(max-width:break($_s, false)){
		display: block;
		width: 100%;
		max-width: 65%;
	}
	@media(min-width:break($_s)){
		display: inline-block;
		position: relative;
		width: auto;
	}

	&.disabled {
		opacity: 0.3;
		pointer-events: none;
	}
}

.button--primary {
	@extend .button;
	background-color: $c-blue;
	color: #fff;
	border: 1px solid transparent;
	transition: background-color $trans-duration;
	&:hover, &:focus {
		background-color: $c-blue-dk;
	}
	.sidebar & {
		margin-bottom: 10px;
	}
}

.button--white {
	@extend .button;
	background-color: #fff;
	color: $c-blue;
	border: 1px solid transparent;
	transition: background-color $trans-duration, color $trans-duration;
	&:hover, &:focus {
		color: #fff;
		background-color: $c-blue-dk;
	}
}

.button--border {
	@extend .button;
	background-color: transparent;
	border: 1px solid $c-blue;
	color: $c-blue;
}

.button--border-brown {
	@extend .button;
	background-color: transparent;
	border: 1px solid $c-brown;
	color: $c-brown;
	transition: background-color $trans-duration, color $trans-duration;
	&:hover, &:focus {
		color: #fff;
		background-color: $c-blue-dk;
	}
}

input[type="submit"] {
	@extend .button;
}
