//CTA
.circle-cta__inner {
  font-size: 0;
}

.circle-cta__col {
  font-size: 15px;
  display: block;
  &:last-child {
    margin-top: 30px;
  }
  @media(min-width:break($_ml)){
    display: inline-block;
    vertical-align: middle;
     &:last-child {
      margin-top: 0;
    }
  }
}

.circle {
  position: relative;
  display: block;
  max-width: 78%;
  margin: 0 auto;
  background-color: transparent;
  text-align: center;
  @media(min-width:break($_s)){
    max-width: 62%;
  }
  @media(min-width:break($_ml)){
    margin: 0;
    max-width: 88%;
  }
}

.circle:after {
  display: block;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: $c-white;
  content: "";
}

.circle__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle__wrapper {
  display: table;
  max-width: 69%;
   @media(min-width:break($_xxl)){
    max-width: 64%;
   }
}

.circle__title {
  margin-bottom: 15px;
  line-height: 1.5;
   @media(min-width:break($_xxl)){
    line-height: 1.8;
   }
}

.circle__content {
  display: table-cell;
  vertical-align: middle;
  .button {
    @extend .mh-a;
  }
}

//CTA - Social
.cta-social{
  overflow: hidden;
}

.cta-social__item {
  @media(max-width:break($_xl, false)){
    &:nth-of-type(7), &:nth-of-type(8) {
      display: none;
    }
    padding-left: 10px;
    padding-right: 10px;
  }
  @media(max-width:break($_s, false)){
    &:nth-of-type(5), &:nth-of-type(6) {
      display: none;
    }
  }
   @media(min-width:break($_ml)){
    padding-left: 20px;
   }
}

.social__list {
  .circle & {
    > li {
      margin: 0 10px;
      @media(min-width:break($_xl)){
        margin: 0 12px;
      > a {
          font-size: 2rem;
        }
      }
    }
  }
}

//CTA - Feature 
.cta-feature__bg-wrapper {
   @media(min-width:break($_xl)){
      //padding-left: 3em;
   }
}