.picks-category {
	@extend .section-padding;
	background-color: $c-cream-lt;
	padding-top: 20px;
	padding-bottom: 10px;
	margin-top: 0 !important;

	@media (min-width: break($_s)) {
		padding-top: 15px;
		padding-bottom: 0;

		.hero + & {
			padding-top: 50px;
		}

		&:last-child {
			padding-bottom: 50px !important;
		}
	}
	
	&:before {
		content: none !important;
	}

	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		a, .current {
			width: 26px;
			height: 26px;
			margin: 0 5px;
			line-height: 26px;
			text-align: center;
		}
		.current {
			background: $c-blue;
			border-radius: 50%;
			color: $c-white;
		}
		.prev,
		.next {
			width: 26px;
			height: 26px;
		}
		.prev {
			margin-left: 0;
			margin-right: 35px;
		}
		.next {
			margin-left: 35px;
			margin-right: 0;
		}
		.icon-previous,
		.icon-next {
			@extend .u-bg-cn;
			width: 6px;
			height: 22px;
			display: inline-block;
			background-image: url('../img/icon-arrow-right.png');
		}
		.icon-previous {
			transform: rotate(180deg);
		}

	}

}
.picks-category__container {
	@extend .container, .max-width, .site-gutter;
}
.picks-category__header {
	margin-bottom: 15px;
	@media (min-width: break($_s)) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 35px;
	}
}
.picks-category__see-all {
	@extend %picks-more-link;
	margin-left: 15px;
	vertical-align: baseline;

	@media (min-width: break($_s)) {
		margin-bottom: 2px;
	}
}
.picks-category__headline {
	@extend %picks-category-headline;
	display: inline-block;
}

.picks-category--general {
	@media (max-width: break($_s, false)) {
		.picks-category__header {
			padding: 0 20px;
		}
		.picks-category__container {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@media (min-width: break($_s)) {
		&:after {
			content: '';
		}
	}
}

.picks-category__grid {
	@extend .card__wrapper, .w1, .flex-grid;

	@media (min-width: break($_s)) {
		justify-content: flex-start;
		width: calc(100% + 20px);
		margin: 0 -10px;
	}
	.card {
		margin-bottom: 20px;
		@media (min-width: break($_s)) {
			width: calc(50% - 20px);
			margin: 0 10px 20px;
		}
		@media (min-width: break($_m)) {
			width: calc(25% - 20px);
		}
	}

	&:hover,
	&:focus {
		outline: 0;
	}

	.card__img {
		opacity: 1;
		background-color: #CCCCCC;
	}
	.card__link-text {
		opacity: 0;
		background: transparent;
	}
	.js-card-hover:hover .card__link-text {
		opacity: 1;
	}
}
.picks-category__slider {
	display: block;
	padding-left: 20px;
	padding-right: 20px;

	@media (min-width: break($_s)) {
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}
	&:after {
		content: 'flickity';
		display: none;

		@media (min-width: break($_s)) {
			content: none;
		}
	}

	.flickity-viewport {
		overflow: visible;
	}
	.card {
		@media (max-width: break($_s, false)) {
			margin-bottom: 0;
			margin-left: 15px;
			margin-top: 0;
			min-height: 100%;
			min-width: 230px;
			width: 76.67%;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}
