.social__list {
  li {
    display: inline-block;
    margin: 0 10px; 
    &:first-child {
        margin-left: 0;
    }
  }
 }

a.social__icon {
    display: block;
    position: relative;
    font-size: 1.4rem;
    text-decoration: none;
    @extend .c-blue;
    @extend .c-hover-blue-dk;
}