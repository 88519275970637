//Page Hero (see landing hero scss for shared hero styles)

.page-hero {
	padding-bottom: $section-spacing;
	@media(min-width:break($_s)){
  	padding-bottom: $section-spacing--lg;
	}
	+ section:not(.bg-c-cream) {
		margin-top: $section-spacing / 2;
	 	@media(min-width:break($_s)){
    	margin-top: $section-spacing--lg / 2;
  	}
	}
}
