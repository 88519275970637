//Curated Events
.curated {
	margin-top: 0;
}
.card {
	.curated & {
		@media(min-width:break($_ml)){
			margin: 0;
		 &:last-of-type {
		 	margin-right: 0;
		 	}
		}
	}
}

.card__wrapper {
	.curated & {
		overflow: hidden;
		margin-top: 10px;
		@media(min-width:break($_ml)){
			margin-top: 30px;
		}
		@media(min-width:break($_xl)){
			padding-left: 20px;
			margin-top: 0;
		}
	}
}

.curated__inner {
	@media(min-width:break($_xl)){
			display: flex;
			align-items: center;
	}
}

.curated__content {
	@media(min-width:break($_xl)){
		//padding-right: 50px;
	}
}

.curated__headline {
	width: 190px;
	margin: 0 auto;
	@media(min-width:break($_ml)){
		width: 220px;
	}
	@media(min-width:break($_xl)){
		width: 100%;
		max-width: 275px;
		margin: 0;
	}
}

.curated__headline-line-two, .curated__headline-line-three {
	display: block;
}

.curated__headline-line-two {
	text-align: right;
	&.on-festival {
		margin-right: 15px;
	}
	&.year-round {
		margin-right: 30px;
	}
}

.curated__headline-line-three {
	text-align: center;
}

.curated__button--sm-screen {
	margin-top: 20px;
	@media(min-width:break($_ml)){
		margin-top: 35px;
	}
}

