// Post
.post__inner {
	max-width: em(1060px);
	margin: 0 auto;
}

.post__content, .post__sidebar, .post__sub-nav {
	flex: 0 100%;
}

.post__sub-nav {
	@extend .section-margin-bottom;
	@media(min-width:break($_s)) {
	&:before, &:after {
			content: '';
			@include o-divider-line-faded;
			position: relative;
			width: 100%;
			display: block;
			margin: 0 auto;

		}
	}
}

.post__content {
	max-width: em(700px);
	width: 100%;
	.has-sidebar & {
		@media(min-width:break($_ml)) {
			flex: 0 2 66%;
		}
	}
	h3 {
		margin-top: 1.6em;
		.page-template-default &,
		.page-template-page-advanced-basic & {
			margin-top: 50px;
			font-size: 22px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	img {
		margin-top: 1.27em;
	}
}

.post__sidebar {
	&:before {
		content: '';
    @extend .o-divider-line-faded;
    position: relative;
    width: 100%;
    display: block;
    margin: 20px auto;
    @media(min-width:break($_s)) {
      margin: 30px auto;
    }
	}
	@media(min-width:break($_ml)) {
		flex: 1 0 30%;
		padding-left: 4%;
		&:before {
			content: none;
		}
	}
}
