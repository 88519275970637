// CODE
pre {
	background-color: rgba(white, .6);
	border-left: 4px solid #148ECC;
	margin: 3rem 0;
	overflow: auto;
	overflow-y: hidden;
	padding: 2rem 3rem;
	code {
		background-color: transparent;
	}
}
code {
	@extend .p, .type-monospace !optional;
	background-color: rgba(white, .6);
	white-space: pre;
}