.calendar-filters {
  overflow: hidden;
  background: white;
  margin-top: -60px;

  &:after {
    content: '';
    @extend .o-divider-line-faded;
    position: relative;
    display: block;
    height: 1px;
  }

  @media (max-width: break($_ml, false)) {
    padding-bottom: 0;
  }
  @media (max-width: $_s+100) {
    margin-top: 0;
  }
}

.calendar-filters__buttons {
  text-align: center;
  overflow: hidden;

  > * {
    display: inline-block;
    position: relative;
    width: auto;
  }

  @media (max-width: break($_s, false)) {
    padding-left: 7.5px;
    padding-right: 7.5px;

    .button--border-brown {
      display: block;
    }

    .button--primary {
      max-width: 100%;
    }

    > * {
      position: relative;
      width: 50%;
      float: left;

      &:first-child {
        left: -7.5px;
        // margin-right: 7.5px;
      }
      &:last-child {
        right: -7.5px;
      }
    }
  }
}

.calendar-filters__select-date {
  position: relative;
  display: inline-block;
  margin-right: 10px;

  @media (max-width: break($_s, false)) {
    display: block;
    margin: 0;

    .button--border-brown {
      width: 100%;
      max-width: 100%;
    }
  }

  label {
    margin: 0;
  }

  input[type="submit"] {
    display: none;
  }

  input {
    // display: none;
    appearance: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
    visibility: hidden;

    // opacity: 0;
  }

  &.focus {
    input {
      opacity: 1;
    }
  }

  &.datepicker-showing .button--border-brown {
    color: $c-blue;
    border-color: $c-blue;
  }
}

.calendar-filters__explore {
  h3, h3 + p {
    display: inline-block;
  }

  h3 + p {
    margin-left: 5px;
  }
}

.calendar-filters__form {
  overflow: hidden;
  position: relative;
  margin-top: 40px;
}

.calendar-filters__category {
  position: relative;
  display: block;
  float: left;
  width: 100%;

  label {
    display: block;
    position: relative;
    margin: 0;
    padding-left: 20px;

    .radio-wrapper {
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
}
.category-name {
  display: block;
}
.calendar-filters__featured {
  position: relative;
  overflow: hidden;

  .calendar-filters__category {
    padding-right: 40px;
    margin-bottom: 30px;

    label {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-flow: row nowrap;
      width: 100%;
    }

    img {
      width: auto;
      height: 42px;
    }
  }
}

.calendar-filters__other {
  position: relative;
  overflow: hidden;

  .calendar-filters__category {
    margin-bottom: 20px;
  }
}

.calendar-filters__fixed-nav {
  display: none;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15px 20px;
  background: white;
  z-index: 899;
  box-shadow: 0 -2px 4px fade-out(black, .95);

  .local-nav__scroll-top {
    display: block;
    float: right;
  }

  .calendar-filters__toggle {
    display: inline-block;
    width: auto;
    margin-right: 10px;
  }
}

.calendar-filters__done {
  display: none;

  .calendar-filters__done-button {
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.calendar-filters__done-button--top {
  float: right;
  width: auto;
  background: none;
  color: $c-gray;
  padding-left: 20px;
  padding-right: 0;

  @media (min-width: break($_s)) {
    position: absolute;
    top: 20px;
    right: 0;
    padding-right: 20px;
  }

  @media (min-width: break($_ml)) {
    display: none;
  }
}

.calendar-filters__tags {
  border-top: 1px solid $c-brown-lt;
  padding-top: 30px;
  @media (min-width: break($_s)) {
    width: 100%;
    padding-top: 0;
    padding-bottom: 30px;
    border-top: 0;
    float: left;
    display: flex;
    align-items: center;
  }
}

.calendar-filters__tags-headline {
  @extend .f5;
  display: block;
}

.calendar-filters__tags-subheadline {
  @extend .small;
  display: block;
}

.calendar-filters__tags-list {
  margin-top: 20px;
  margin-bottom: 30px;
  @media (min-width: break($_s)) {
    margin: 0 0 0 25px;
  }
}

.calendar-filters__tag {
  @extend .button--border;
  position: relative;
  width: auto;
  display: inline-block;
  margin: 0 5px 10px;
  padding: 6px 25px;
  font-size: 12px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-transform: none;
  border-radius: 100px;
  transition: all 0.3s ease;
  cursor: pointer;
  pointer-events: none;
  &.js-tag-select {
    background: #0D58D2;
    color: $c-white;
  }
  &.js-is-disabled {
    opacity: 0.2;
  }
}

.calendar-filters__tag-label {
  pointer-events: none;
}

.calendar-filters__tag-input {
  @extend .u-fill;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: visible;
  cursor: pointer;
}

@media (min-width: break($_s)) and (max-width: break($_ml, false)) {
  .calendar-filters__other {
    .calendar-filters__category {
      width: 25%;
    }
  }
}

@media (min-width: break($_s)) and (max-width: break($_xl, false)) {
  .calendar-filters__featured {
    .calendar-filters__category {
      width: 50%;

      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}

@media (min-width: break($_ml)) {
  .calendar-filters__buttons {
    float: right;
  }
  .calendar-filters__other {
    float: left;
    clear: none;
    width: 25%;
    padding-left: 50px;
    border-left: 1px solid $c-brown-lt;
  }
}

@media (min-width: break($_xl)) {
  .calendar-filters__featured {
    .calendar-filters__category {
      width: 20%;
    }
  }
}

@media (max-width: break($_ml, false)) {
  .calendar-filters:after {
    margin-top: 30px;
  }
  .calendar-filters__explore {
    position: fixed;
    top: $header-height;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: $c-cream;
    z-index: 900;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    text-align: center;

    h3, h3 + p {
      display: block;
    }

    display: none;

    .calendar-filters-open & {
      display: block;
    }
  }
  .calendar-filters__fixed-nav {
    display: block;
  }
  .calendar-filters__form {
    text-align: left;
  }
  .calendar-filters__featured {
    margin-right: -40px;
    padding-bottom: 10px;
  }
  .calendar-filters__other {
    padding-top: 20px;
    border-top: 1px solid $c-brown-lt;
  }
  .calendar-filters__done {
    display: block;
    clear: both;
    text-align: center;
  }
}

@media (max-width: break($_s, false)) {
  .calendar-filters__explore {
    text-align: left;
  }
  .calendar-filters__other {
    .calendar-filters__category {
      width: 50%;
    }
  }
  #ui-datepicker-div {
    left: 50% !important;
    transform: translateX(-50%) !important;
    &:before {
      display: none;
    }
  }
}