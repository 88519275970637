.js-local-subnav-active {
  .local-subnav {
    visibility: visible;
    opacity: 1;
    z-index: 9999;
  }
}
.local-subnav__container {
  padding: 0 35px;
}

.local-subnav__inner {
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.local-subnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background: $c-white;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.02);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.local-subnav__left,
.local-subnav__button-top {
  @media (max-width: break($_m, false)) {
    display: none;
  }
}

.local-subnav__right {
  @media (max-width: break($_m, false)) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.local-subnav__label {
  @extend %schedule-title;
  font-size: 15px;
}

.local-subnav__description {
  @extend %typo-quote-carousel__position;
}

%typo-button-to-top {
  color: $c-gray;
  font-size: 1.1rem;
  line-height: 1.7rem;
  letter-spacing: .2rem;
  font-weight: 700;
}

.local-subnav__button-top {
  @extend %typo-button-to-top;
  margin-left: 2rem;
  padding: 0;
  background: none;
  i {
    margin: 0 0.5rem 0 0;
    font-size: 6px;
    position: relative;
    top: -2px;
  }
}