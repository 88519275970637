// BREAKPOINTS
// These should be configured for the project.
// The namespace `$_*` refers to the suffixes
// used throughout the rest of the project.
$_xs: 399px;
$_s: 450px;
$_m: 768px;
$_ml: 850px;
$_l: 930px;
$_xl: 1024px;
$_xxl: 1440px;

// This function converts values to ems,
// regardless of units.
@function em($value) {
	@if ($value/1) == $value {
		@if (unit($value) == 'px') {
			$unitless_value: $value/1px;
			@return ($unitless_value/16)*1em;
		} @else if (unit($value) == 'pt') {
			$unitless_value: $value/1pt;
			@return ($unitless_value/16)*1em;
		} @else {
			@return ($value/16)*1em;
		}
	}
	@warn "em(#{$value}) is not a number.";
	@return null;
}

// BREAKPOINT FUNCTION
// This function can be used with min-width or
// max-width queries, and converts the pixel
// value breakpoint variables at the top of this
// doc to ems for the rendered @media blocks.
@function break($breakpoint, $min-width: true) {
	@if ($min-width == false) {
		@return $breakpoint - 1; // subtract on integer for max-width based queries
	} @else {
		@return $breakpoint;
	}
	@warn "#{$breakpoint} in function break(#{$breakpoint}) does not exist.";
	@return 'Check your break() declaration.';
}

@mixin not-mobile($breakpoint: $_ml) {
  @media (min-width: $breakpoint) {
    .no-touchevents & {
      @content;
    }
  }
}

//Hide/show at breakpoint
@each $size, $breakpoint in
("xs", $_xs),
("s", $_s),
("m", $_m),
("ml", $_ml),
("l", $_l),
("xl", $_xl),
("xxl", $_xxl),
{
	.hide-#{$size} {
		@media(max-width:break($breakpoint, false)){
			display: block;
		}
		@media(min-width:break($breakpoint)){
			display: none;
		}
	}
	.show-#{$size} {
		@media(max-width:break($breakpoint, false)){
			display: none;
		}
		@media(min-width:break($breakpoint)){
			display: block;
		}
	}
	.hide-#{$size}-max {
		@media(max-width:break($breakpoint, false)){
			display: none;
		}
	}
	.show-#{$size}-max {
		@media(min-width:break($breakpoint)){
			display: none;
		}
	}
	.hide-#{$size}-min {
		@media(min-width:break($breakpoint)){
			display: none;
		}
	}
	.show-#{$size}-min {
		@media(max-width:break($breakpoint, false)){
			display: none;
		}
	}
}

