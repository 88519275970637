// Header
@mixin navlink {
	font-size: 1.25rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 2px;
	line-height: 1.5;
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2001;
	box-shadow: 0px 0px 5px 0px rgba($c-brown, 0.14);
	transition: transform 0.3s ease;
	@media (min-width: 600px) {
		z-index: 998;
	}
}

@keyframes dropHeader {
    0% {
        transform: translateY(-85px);
        opacity: 0;
    }
    20% {
        transform: translateY(-85px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.header__inner {
	position: relative;
	background-color: $c-white;
	height: $header-height;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: background-color $trans-duration ease-in-out;
	@media (min-width: break($header-breakpoint)) {
		height: $header-height--lg;
		padding: 0 $header-gutter--lg;
	}
	&.is-open {
		background-color: $c-cream;
		z-index: 99;
	}
}

.header__logo {
	text-indent: -9999px;
	z-index: 1;
	width: 80px;
	display: block;
	position: absolute;
	top: 0;
	height: 100%;
	left: 50%;
	transform: translateX(-40px);
	@media(min-width: break($header-breakpoint)) {
		width: 125px;
		bottom: 0;
	}
}

.header__logo--image {
	.header__logo-img {
		margin-top: 5px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.header__logo-img {
	padding-bottom: 53.3%;
	margin-top: -10px;
	#jpd-logo {
		transition: fill $trans-duration ease-in-out;
		fill: $c-brown;
		&:hover {
			fill: $c-blue;
		}
	}
}

//NAV

.nav__links-wrapper {
	opacity: 0;
	visibility: hidden;
	//max-height: 0;
	overflow: hidden;
	background-color: $c-cream;
	transition: opacity $trans-duration ease-in-out, visibility $trans-duration ease-in-out;
	box-shadow: inset 0 4px 10px -8px rgba($c-brown,0.3);
	&.is-open {
		overflow-y: auto;
		height: 100vh;
		visibility: visible;
		transition: opacity $trans-duration ease-in-out, visibility $trans-duration ease-in-out;
		//max-height: 100vh;
		opacity: 1;
		&.is-faded {
			opacity: 0;
		}
	}
	@media(min-width:break($header-breakpoint)){
		opacity: 1;
		visibility: visible;
		overflow: visible;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		max-height: none;
		background-color: $c-white;
		box-shadow: none;
	}
}

.nav__links {
	position: relative;
	@media(min-width:break($header-breakpoint)){
		display: flex;
		height: 100%;
	}
	.pre-selected {
		> a {
			color: $c-blue;
		}
	}
	> li {
		position: relative;
		height: 100%;
		//width: 100%;
		margin: 0;
		padding: 0 15px;

		&.menu-search {
			order: 99;
			a {
				text-indent: -9999px;
			}
			&:hover {
				&:before {
					color: $c-blue;
				}
			}
			&:before {
				font-size: 14px;
				left: 0;
				right: 0;
				text-align: center;
				@media(min-width:break($header-breakpoint)) and (max-width:break($_xl, false)){
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			&.is-open {

				&:before {
					color: $c-blue;
					font-size: 8px;
				}
				a {
					color: $c-blue;
				}
			}
		}

		&.menu-buy-tickets {
			> a {
				@extend .button;
				@extend .button--primary;
				@extend .bg-hover-c-blue-dk;
				color: #fff !important;
			}
			&:hover {
				background: transparent;
				> a {
					color: #fff;
				}
			}
			@media(max-width:break($header-breakpoint)){
				margin: 0 auto;
				text-align: center;
				padding-top: 30px;
				> a {
					display: inline-block;
					width: auto;
				}
			}
		}

		> a {
			@include navlink;
			padding: 15px 0;
			border-bottom: 1px inset rgba($c-brown, 0.07);
			//box-shadow: 0px -2px 3px -2px rgba($c-brown, 0.14);
			@media(min-width:break($header-breakpoint)){
				border-bottom: none;
			}

			&:after {
				content: none !important;
			}
		}
		a {
			display: block;
			@extend .c-hover-blue;
		}
		.sub-menu {
			position: relative;
			list-style: none;
			background-color: $c-cream;
			@media(max-width:break($header-breakpoint, false)){
				display: none;
			}
		}
		.sub-menu--level-0 {
			//box-shadow: inset 0 2px 3px -4px rgba($c-brown, 0.14), inset 0 -2px 3px -4px rgba($c-brown, 0.14);
			@extend .pv-10;
			padding-left: 25px;
			border-bottom: 1px inset rgba($c-brown, 0.07);
			> li {
				//margins causing slight shake on expand
				margin: 0;
				> a {
					line-height: 1.3;
					padding: 8px 0;
				}
			}
			@media(min-width:break($header-breakpoint)){
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 900;
				max-width: 500px;
				min-width: 200px;
				width: auto;
				height: auto;
				opacity: 0;
				visibility: hidden;
				transition: opacity $trans-duration ease-in-out;
				box-shadow: inset 0 4px 10px -8px rgba($c-brown,0.3);
				padding: 10px 15px;
				border-bottom: none;
				> li {
					&:last-child a {
						border-bottom: none;
					}
					> a {
						padding: 10px 0;
						border-bottom: 1px inset rgba($c-brown, 0.07);
						margin-left: 5px;
						margin-right: 5px;
					}
				}
			}
		}
		.sub-menu--level-1 {
			//@extend .pb-10;
			padding-left: 15px;
			> li {
				margin: 0;
				> a {
					padding: .33em 0;
				}
			}
			@media(min-width:break($header-breakpoint)){
				display: none;
				padding-left: 10px;
			}
		}
		&.menu-item-has-children, &.menu-item-has-children .menu-item-has-children {
			> a {
				@include navlink;
				position: relative;
				//line-height: 2;
				&:after{
					position: absolute;
					right: 0;
					top: .625em;
					//margin-right: 5px;
					content: '\e818';
					font-family: "jp-icons";
					font-size: 16px;
					line-height: 1.5;
					color: rgba($c-brown, .3);
				}
				@media(min-width:break($header-breakpoint)){
					&:after {
						content: none;
					}
				}
			}
			&.pre-selected,
			&.is-open {
				> a {
					color: $c-blue;
					&:after{
						content: '\e819';
					}
				}
				@media(min-width:break($header-breakpoint)){
					&:after {
						content: none;
					}
					//&.pre-selected,
					&.is-open {
						border-bottom: 1px inset rgba($c-brown, 0.07);
					}
				}
			}
			&.pre-selected {
				> ul:not(.sub-menu--level-0) {
					display: block;
				}
			}
		}
		/* Festival Mega Menu */
		&.mega-festival-trigger {
			.mega-menu__container {
				@media(min-width:break($header-breakpoint)){
					opacity: 0;
					visibility: hidden;
				}
				.sub-menu--level-0 {
					padding: 10px 25px;
					@media(min-width:break($header-breakpoint)){
						box-shadow:none;
						padding: 0 20px 0 0;
						order: 1;
						flex: 0 1 auto;
						position: relative;
					}
				}
				.mega-menu__posts {
					@media(max-width:break($header-breakpoint)){
						display: none;
					}
				}
				.card__wrapper {
					flex-flow: row nowrap;
				}
				.card {
					width: 100%;
					max-width: 180px;
					margin-right: 20px;
					background-color: transparent;
					.mega-menu__post-title, .mega-menu__post-date {
						transition: color $trans-duration ease-in-out;
					}
					&:hover {
						.mega-menu__post-title, .mega-menu__post-date {
							color: $c-blue;
						}
					}
				}
				.card__content {
					background-color: transparent;
				}
			}
		}
		//Hide search link on mobile
		&.menu-search {
			display: none;
		}
		//More Dropdown icons
		.sub-menu--level-0 {
			> .menu-item-has-children {
				> a {
					position: relative;
					&:after{
						position: absolute;
						right: 0;
						top: .75em;;
						content: '\e818';
						font-family: "jp-icons";
						font-size: 12px;
						line-height: 1.5;
						color: rgba($c-brown, .3);
					}
				}
				&.pre-selected,
				&.is-open {
					> a {
						color: $c-blue;
						&:after{
							content: '\e819';
						}
					}
				}
			}
		}
		@media(min-width:break($header-breakpoint)){
			display: flex;
			align-items: center;
			width: auto;
			&:hover {
				background-color: $c-cream;
				transition: background-color $trans-duration ease-in-out;

				&.menu-search,
				&.menu--ticket-button {
					background-color: transparent;
				}
				&.is-open {
					background-color: $c-cream;
				}
				.sub-menu--level-0 {
					opacity: 1;
					visibility: visible;
				}
				> a, > a:hover {
					color: $c-blue;
				}

				&.menu--ticket-button {
					a {
						color: $c-white;
					}
				}

				&.menu-search {
					&:before {
						@include c-hover-blue;
					}
				}
			}
			&.menu--ticket-button {
				a {
					padding: 10px 20px;
				}
			}
			//Hide "Search" and just show icon
			&.menu-search {
				display: flex;
				> a {
					display: none;
					@media(min-width:break($_xl)){
						display: block;
						padding-left: 5px;
					}
				}
			}
			//Festival dropdown hover
			&.mega-festival-trigger {
				&:hover {
					.mega-menu__container {
						opacity: 1;
						visibility: visible;
					}
					.sub-menu--level-0 {

					}
				}
			}
			&.menu-item-has-children {
				&:after{
					content: '';
				}
			}
			& a {
				display: block;
				/* display: none;
				@media(min-width:break($_xl)){
				display: inline-block;
			} */
		}
	}
}
}

.nav__primary {
	margin-top: 10px;
	@media(min-width:break($header-breakpoint)){
		margin-top: 0;
		left: 0;
	}
}

.nav__quick-links {
	> li > a {
		text-transform: none;
		font-weight: 400;
		font-size: 1.5rem;
		letter-spacing: 0;
		@media(min-width:break($_s)){
			font-size: 1.6rem;
		}
	}
	@media(min-width:break($header-breakpoint)){
		right: 0;
		> li > a {
			@include navlink;
		}
	}
}

.nav__mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	//padding: 0 $header-gutter;
	@media(min-width:break($header-breakpoint)){
		display: none;
	}
}

.social__list {
	.nav__links-wrapper & {
		padding: 20px 0;
		text-align: center;
		.social__icon {
			@extend .c-brown;
			@extend .c-hover-brown-dk;
			font-size: 1.2rem;
		}
		@media(min-width:break($header-breakpoint)){
			display: none;
		}
	}
}

//Quick Links

/* Search */
.menu-search, .nav__mobile-search {
	position: relative;
	&:before {
		content: '\e80d';
		cursor: pointer;
		display: inline-block;
		@extend .p-vc; //absolute vertical alignment
		font-family: 'jp-icons';
	}
	&.is-open {
		background-color: $c-cream;
		&:before {
			content: '\e81c';
			font-size: 10px;
		}
		a {
			color: $c-blue;
		}
	}
}

.nav__mobile-search {
	align-self: stretch;
	cursor: pointer;
	padding: 0 30px;
	&:hover{
		color: $c-blue;
		background-color: $c-cream;
	}
	&:before {
		font-size: 14px;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&.is-open {
		color: $c-blue;
		&:before {
			font-size: 11px;
		}
	}
}

.nav__trigger {
	padding: $site-gutter;
	cursor: pointer;
}

#search__container {

}

.search__input-group {
	input {
		&:first-child {
			width: 100%;
		}
	}
	.header__search & {
		border-width: 0 0 1px 0;
		transition: border-color $trans-duration ease-in-out;
	}
}

.search__container-inner {
	display:none;
	width: 100%;
	background-color: $c-cream;
	padding:1em;
	position:fixed;
	left: 0;
	z-index:999;
	box-shadow: inset 0 4px 10px -8px rgba($c-brown,0.3);
	@media(min-width:break($_s)){
		left: 0;
		right: auto;
		margin-right: 0;
	}
	@media(min-width:break($header-breakpoint)){
		right: 0;
		left: auto;
		margin-right: $header-gutter--lg;
		max-width: 500px;
	}
	.search__input-group {
		max-width: 100%;
	}
}

.search__content {
	height:100%;
	margin:0 auto;
	position:relative;
	text-align: center;
	max-width:1280px;
}

.search__close {
	cursor:pointer;
	display:none;
	height:auto;
	position:absolute;
	right:5%;
	width:18px;
	z-index:10;
	opacity: 1;
}

//Mega Menu
@media(min-width:break($header_breakpoint)){
	.mega-menu__container {
		position: fixed;
		//right: 0;
		top: $header-height--lg;
		left: $header-gutter--lg;
		opacity: 0;
		visibility: hidden;
		transition: opacity $trans-duration ease-in-out;
		z-index: 2;
	}
	.mega-menu__inner {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-flow: row nowrap;
		position: relative;
		width: 100%;
		max-width: em(1070px);
		margin: 0 auto;
		background-color: $c-cream;
		box-shadow: inset 0 4px 10px -8px rgba($c-brown,0.3);
		padding: 10px 15px;
		border-bottom: none;
	}
}
.mega-menu__posts {
	order: 2;
	flex: 1 0 auto;
}

.mega-menu__post-title {
	line-height: 1.3;
	letter-spacing: normal;
}
