//Home Hero (see landing hero scss for shared hero styles)

.hero {
  @media(min-width:break($_ml)){
    max-height: 685px;
    overflow: hidden;
  }
}
.hero__bg{
  height: 0;
  padding-top: 56.25%;
  &:after{
    content:'';
    display:block;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background-color:#000;
    opacity:0.2;
  }
}

.hero__button {
  .jp-icon-play {
    width: 17px;
    height: 17px;
    line-height: 17px;
    background-color: $c-white;
    border-radius: 50%;
    text-align: center;
    color: $c-blue;
    display: inline-block;
    font-size: 7px;
    text-align: center;
    vertical-align: top;
    margin-right: 3px;

    &:before {
      display: inline-block;
      transform: translate(2px, -1px);
      vertical-align: middle;
    }
  }
  
  &:hover {
    .jp-icon-play {
      color: $c-blue-dk;
    }
  }
}

.overlay {
.home-hero-yr & {
    z-index: 1001;
  }
}

.home-hero-yr__content {
  @media(min-width:break($_ml)){
    z-index: 1002;
  }
}

.slick-dots {
  .home-hero-yr & {
    position: relative;
    bottom: 0;
    margin: 15px 0;
    @media(min-width:break($_ml)){
      position: absolute;
      bottom: 15px;
    }
  }
}

.slick-slide {
  .home-hero-yr & {
    @media(min-width:break($_ml)){
      max-height: em(685px);
    }
  }
}
