// ALIGNMENT
.align-l {
	text-align: left;
}
.align-c {
	text-align: center;
}
.align-r {
	text-align: right;
}
.align-j {
	text-align: justify;
}
.align-m {
	vertical-align: middle;
}
.align-t {
	vertical-align: top;
}
.align-b {
	vertical-align: baseline;
}
.align-v-c{
  display: table;
  
  > *{
    display: table-cell;
    vertical-align: middle;
  }
}
.align-v-t{
  display: table;
  width: 100%;
  > *{
    display: table-cell;
    vertical-align: top;
  }
}
.align-v-m{
  line-height: 100%; 
  
  > *{
    display: inline-block;
    vertical-align: middle;
  }
}
//Flex alignment
.align-f-c {
  align-items: center;
}
.align-f-s {
  align-items: flex-start;
}