//Media Gallery

section:not(.bg-c-cream) 
+ .media-gallery {
	margin-top: $section-spacing / 2;
	@media(min-width:break($_xl)){
		margin-top: $section-spacing--lg / 2;
	}
}

.media-gallery {
	position: relative;
	background: black;
	color: white;
	overflow: hidden;
	margin-bottom: 40px;
	z-index: 0;

	* {
		color: white;
	}

	.carousel.sliding .carousel-inner {
		transition: height .6s ease-in-out;
	}
	@media(min-width:break($_ml)){
		margin-bottom: 50px;
	}
	@media(min-width:break($_xl)){
		margin-bottom: 60px;
	}
	+ .section-padding {
		padding-top: 0;
	}
	@media(max-width:break($_ml, false)){
		+ .section-padding.three-six-grid {
				padding-top: $section-spacing;
		}
	}
}

.media-gallery__bg-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}

.media-gallery__bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-size: cover;
	background-position: 50% 50%;
	z-index: 0;
	opacity: 0;
	transition: opacity .6s 0s;
	&:before {
		content: '';
		position: absolute;
		@include p-fill;
		background: rgba(0,0,0, .5);
	}
	&.active {
		z-index: 1;
		opacity: 1;
		transition: opacity .6s;
	}
}

.media-gallery__title {
	position: relative;
	z-index: 2;
	width: 320px;
	max-width: 100%;
	margin: auto;
	@media (min-width: break($_ml)) {
		h2 {
			max-width: 295px
		}

		float: left;
		> div {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
		}
	}

	@media (max-width: break($_ml, false)) {
		height: auto !important;
		margin-bottom: 20px;
	}

	@media (max-width: break($_xl, false)) {
		width: 230px;
	}

	@media (max-width: break($_s, false)) {
		width: 215px;
	}
}

.media-gallery__title-the-media {
	display: block;
	text-align: right;
}

.media-gallery__title-gallery {
	display: block;
	margin-left: 40px;
}

.media-gallery__pager {
	margin-top: 20px;
	vertical-align: middle;

	@media (max-width: break($_ml, false)) {
		text-align: center;
	}
}

.media-gallery__control {
	position: relative;
	display: inline-block;
	font-size: 2.4rem;
	padding: 0 20px;
	top: 2px;

	&.disabled {
		opacity: 0.5;
		cursor: default;
	}

	&[data-slide] {
		transition: all 0.3s;
	}
	
	&:hover {
		&[data-slide="next"] {
			transform: translateX(3px);
		}
		&[data-slide="prev"] {
			transform: translateX(-3px);
		}
		opacity: 0.6;
	}
}

.media-gallery__slides-wrapper {
	position: relative;
	width: 100%;
	padding: 0 22.5px;
	z-index: 1;
	overflow: visible;

	@media (max-width: break($_m, false)) {
		padding: 0;
	}

	@media (min-width: break($_ml)) {
		margin-left: -230px;
		padding-left: 230px;
		padding-right: 1%;
	}

	@media (min-width: break($_xl)) {
		margin-left: -320px;
		padding-left: 320px;
	}

	@media (min-width: break($_l)) {
		padding-right: 5%;
	}

	.carousel-inner {
		position: relative;

		@media (max-width: break($_m, false)) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}
}

$slide-left-x: -10%;
$slide-right-x: 100%;
$slide-next-right-x: 200%;
$slide-right-opacity: 0.35;

.media-gallery__slide {
	display: none;
	position: relative;
	width: 100%;
	padding: 0 15px;
	transform: translateX(0);
	transition: .6s ease-in-out;
	transition-property: transform, opacity;

	figcaption {
		@extend %caption;
		text-align: center;
		position: relative;
		opacity: 0;
		transition: opacity .6s ease-in-out;

		@media (max-width: break($_ml, false)) and (min-width: break($_m)) {
			margin-left: -30px;
			margin-right: -30px;
		}
	}

	@media (max-width: break($_m, false)) {
		padding: 0 20px;
	}

	&.active ~ .media-gallery__slide,
	&.prev.right {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.active {
		display: block;

		figcaption {
			opacity: 1;
		}

		~ .media-gallery__slide {
			transform: translateX($slide-next-right-x);
			opacity: 0;
		}

		&:not(.right) + .media-gallery__slide {
			transform: translateX($slide-right-x);
			opacity: $slide-right-opacity;

			&.next.left,
			&.prev.right {
				transform: translateX(0);
				opacity: 1;
			}
		}

		&.left {
			transform: translateX($slide-left-x);
			opacity: 0;
		}

		&.right {
			transform: translateX($slide-right-x);
			opacity: $slide-right-opacity;

			figcaption {
				opacity: 0;
			}
		}
	}

	&.next.left,
	&.prev.right {
		figcaption {
			opacity: 1;
		}
	}

	&.prev.right {
		animation: SlideInFromLeft .6s ease-in-out;
	}

	&.next.left + .media-gallery__slide {
		transform: translateX($slide-right-x);
		opacity: $slide-right-opacity;
		transition-duration: .75s;
	}
}

.media-gallery__slide-media {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: (9/16) * 100%;
	pointer-events: none;

	.media-gallery__slide.active & {
		pointer-events: auto;
	}

	video, img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		right: 0;
		transform: translateX(-50%);
		//width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&.video__thumbnail {
		cursor: pointer;
	}
}

@keyframes SlideInFromLeft {
	0% {
		transform: translateX($slide-left-x);
		opacity: 0;
	}
	100% {
		transform: none;
		opacity: 1;
	}
}