.aspect-25{
  padding-top: 25%;
}
.aspect-35{
  padding-top: 35%;
}
.aspect-50{
  padding-top: 50%;
}
.aspect-56{
  padding-top: 56.25%; //16:9 ratio
}
.aspect-60{
  padding-top: 60%;
}
.aspect-65{
  padding-top: 65%;
}
.aspect-80{
  padding-top: 80%;
}
.aspect-83 {
  padding-top: 83.333%;
}
.aspect-100{
  padding-top: 100%;
}

@mixin aspect($width, $height, $position: 'before') {
  &:#{$position} {
    content: '';
    display: block;
    padding-top: percentage($height/$width);
  }
}