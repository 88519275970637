@font-face {
  font-family: 'jp-icons';
    src:    url('../fonts/jp-icons.eot?oolidh');
    src:    url('../fonts/jp-icons.eot?oolidh#iefix') format('embedded-opentype'),
        url('../fonts/jp-icons.ttf?oolidh') format('truetype'),
        url('../fonts/jp-icons.woff?oolidh') format('woff'),
        url('../fonts/jp-icons.svg?oolidh#jp-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

i {
  font-family: 'jp-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jp-icon-dot:before { content: '\e800'; } 
.jp-icon-dot-thin:before { content: '\e801'; } 
.jp-icon-empty:before { content: '\e802'; } 
.jp-icon-invalid:before { content: '\e803'; } 
.jp-icon-next:before { content: '\e804'; } 
.jp-icon-email:before { content: '\e805'; }
.jp-icon-instagram:before { content: '\e806'; }
.jp-icon-youtube:before { content: '\e807'; }
.jp-icon-facebook:before { content: '\e808'; }
.jp-icon-twitter:before { content: '\e809'; }
.jp-icon-fullscreen:before { content: '\e80a'; } 
.jp-icon-pause:before { content: '\e80b'; } 
.jp-icon-play:before { content: '\e80c'; }
.jp-icon-search:before { content: '\e80d'; } 
.jp-icon-close:before { content: '\e80e'; } 
.jp-icon-arrow-down-scroll:before { content: '\e80f'; }
.jp-icon-arrow-right:before { content: '\e810'; }
.jp-icon-arrow-left:before { content: '\e811'; }
.jp-icon-arrow-down:before { content: '\e812'; }
.jp-icon-arrow-up:before { content: '\e813'; }
.jp-icon-divider:before { content: '\e814'; }
.jp-icon-logo:before { content: '\e815'; } 
.jp-icon-exit-fullscreen:before { content: '\e816'; } 
.jp-icon-valid:before { content: '\e817'; }
.jp-icon-plus:before { content: '\e818'; } 
.jp-icon-minus:before { content: '\e819'; } 
.jp-icon-arrow-thin-left:before { content: '\e81a'; } 
.jp-icon-arrow-thin-right:before { content: '\e81b'; } 
.jp-icon-cancel:before { content: "\e81c"; }
