.calendar-title {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px + 60px;
	
	@media (max-width: $_s + 100) {
		padding-top: 15%;
		padding-bottom: 15%;
	}

  h1 {
    position: relative;
    color: white;
    z-index: 1;
  }
}

.calendar-title__bg {
	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: fade-out(black, .8);
	}
}