%schedule-sm-col {
  @media (min-width: break($_m)) {
    flex: 1 0 auto;
    width: 27%;
    max-width: 27%;
    padding-left: 40px;
  }
}
.schedule-item {
  &:first-of-type {
    border-top: 1px solid $c-brown-lt;
  }
  &:last-child {
    margin-bottom: 20px;
  }
  border-bottom: 1px solid $c-brown-lt;
}
.schedule-item__header {
  @extend %content-large;
  height: 96px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: 700;
  cursor: pointer;
}
.schedule-item__toggle {
  width: 36px;
  height: 36px;
  display: block;
  cursor: pointer;
  float: right;
  position: relative;
  .accordion-icon-bar {
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: $c-blue;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    &:nth-child(2) {
      transform: rotate(-90deg);
      transition: transform 0.08s ease-in;
      transition: all 0.2s;
      opacity: 1;
      .accordion-expanded & {
        transform: none;
      }
      .schedule-item__active &{
        opacity: 0;
      }
    }
  }
}
.schedule-item__intro {
  @media (min-width: break($_m)) {
    display: flex;
  }
}
.schedule-item__body {
  padding-left: 20px;
  @media (min-width: break($_m)) {
    padding-left: 68px;
  }
}
.schedule-item__content {
  padding-bottom: 15px;
}
.schedule-item__venue {
  @extend %schedule-sm-col;
}
.schedule-item__cta {
  padding-bottom: 15px;
  @media (min-width: break($_m)) {
    flex: 1 0 auto;
    padding-left: 60px;
  }
}
.schedule-item__list-item {
  @extend .p;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid $c-brown-lt;
  border-bottom: 1px solid $c-brown-lt;
  &:last-of-type {
    border-bottom: none;
  }
  @media (min-width: break($_m)) {
    display: flex;
    padding-top: 22px;
    padding-bottom: 25px;
  }
}
.schedule-item__location {
  @media (min-width: break($_m)) {
    flex: 1 0 auto;
    width: 46%;
  }
}
.schedule-item__location-title {
  @extend %schedule-title;
  margin-bottom: 10px;
}
.schedule-item__location-icon{
  position: relative;
  top: 7px;
  background-image: url(../img/icon-location.png);
  background-repeat: no-repeat;
  background-size: 15px;
  width: 15px;
  height: 20px;
  display: inline-block;
}
.schedule-item__location-link {
  color: $c-blue;
  a {
    border-bottom: 1px solid $c-blue;
  }
}
.schedule-item__time {
  @extend %schedule-sm-col;
}
