//Quotes
.quotes {
	+ section:not(.bg-c-cream) {
		&:before {
			content: '';
			@extend .o-divider-line-faded;
			position: relative;
			width: 100%;
			display: block;
			margin: 0 auto $section-spacing;
		 	@media(min-width:break($_s)){
	    	margin-bottom: $section-spacing--lg;
	  	}
		}
	}
	+ section.three-six-grid {
		@media(max-width:break($_ml, false)){
			margin-top: $section-spacing / 2;
		}
	}
}

.quote__author {
	margin: 15px 0;
}

.rte {
	&.quote__container {
		blockquote {
			margin: 0;
			p:not(.byline):not(.affiliation) {
				text-transform: none;
				@extend .intro-text;
				@extend h3;
				color: $c-brown;
				line-height: 1.3;
			}
			&:before, &:after {
				content: none;
				margin: 0;
			}
		}
	}
}

.quotes__footnote {
	max-width: em(475px);
	p:last-child {
		margin-bottom: 0;
	}
}

.js-quote-carousel { display: none; }
.js-quote-carousel.slick-initialized { display: block; }

//Carousel
$slick-font-family: "jp-icons";
$slick-dot-color: $c-blue;
$slick-dot-color-active: $slick-dot-color;
$slick-dot-character: "\e801";
$slick-active-dot-character: "\e800";
$slick-dot-size: 10px;

.slick-dots {
	.quotes & {
		position: relative;
		bottom: 0;
	}
}

.slick-dots li.slick-active button:before {
	.quotes & {
	content: $slick-active-dot-character;
	font-family: $slick-font-family;
	color: $slick-dot-color;
	font-size: $slick-dot-size;
	opacity: 1;
	}
}

.slick-dots li button:before {
	.quotes & {
	content: $slick-dot-character;
	font-family: $slick-font-family;
	color: $slick-dot-color;
	font-size: $slick-dot-size;
	opacity: 1;
	}
}
