.page-title {
	.event-hero & {
		position: relative;
		z-index: 1;
		display: block;
		margin: 0 auto .5em;
		h1 {
		color: $c-white;
		}
	}
}
.page-title {
	.event-hero__nobg & {
		margin-bottom: .5em;
		h1 {
		color: $c-brown;
		}
	}
}

.event-hero {
	//z-index: -3;
	+ section:not(.bg-c-cream) {
			margin-top: $section-spacing / 2;
	 	@media(min-width:break($_s)){
	 		margin-top: $section-spacing--lg / 2;
	 	}
		@media(min-width:break($_xl)){
			padding-top: 60px;
			margin-top: 0;
		}
	}
}

.event-hero__bg-wrapper {
	background-color: #000;
	&.section-padding {
		padding-top: $section-spacing;
    padding-bottom: $section-spacing;
    @media(min-width:break($_s)){
      padding-top: $section-spacing--lg;
      padding-bottom: $section-spacing--lg;
    }
	}
}

.event-hero__bg-blur {
	z-index: 0;
	opacity: 0;
	transition: opacity $trans-duration;
}

.event-hero__video-wrapper {
	max-width: 1000px;
	@media(min-width:break($_s)){
		max-width: em(410px);
	}
	@media(min-width:break($_ml)){
		max-width: em(590px);
	}
	@media(min-width:break($_xl)){
	max-width: em(1000px);
	}
}

.event-hero__fallback {
	height: 0;
	padding-top: 56.25%;
}

.overlay {
	.event-hero & { 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
		height: 250px; 
    background: linear-gradient(180deg, rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 100%); 
  }
}