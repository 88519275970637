//2-up Image
.two-up-image__inner {
	@media(min-width:break(639px)){
		display: flex;
		align-items: center;
	}

	.two-up-image--alt & {
		flex-direction: row-reverse;
	}
}
.two-up-image__content {
	margin-top: 20px;

	a:not(.button--primary) {
		color: $c-blue;
		text-decoration: underline;

		&:hover {
			color: $c-blue-dk;
		}
	}
	@media(min-width:break(639px)){
		padding-right: 40px;
		margin-top: 0;
	}
	@media(min-width:break($_xl)){
		padding: 3em 3em 3em 0;

		.two-up-image--alt & {
			padding: 3em 0 3em 3em;
		}
	}
}

.two-up-image__content-wrap {
	position: relative;
	@media(min-width:break(639px)){
		width: 50%
	}
	@media(min-width:break($_xl)){
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 33%;
	}
}

.two-up-image__bg-wrapper {
	@media(min-width:break(639px)){
		width: 50%;
		order: 1;
	}
	@media(min-width:break($_xl)){
		width: 79%;
		margin-right: 0;
		margin-left: auto;

		.two-up-image--alt & {
			margin-left: 0;
			margin-right: auto;
		}
	}
}

.two-up-image__button-wrapper {
	@media(max-width:break(638px)){
		.button--primary {
			display: inline-block;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
