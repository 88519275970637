.people-quote.bg-c-cream {
  padding-bottom: 50px;
  margin-bottom: 50px;
  position: relative;

  @media (min-width: break($_m)) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
.people-quote__headline {
  @extend .f4;
  margin-bottom: 10px;
  text-align: center;
  
  @media (min-width: break($_m)) {
    text-align: left;
    margin-bottom: 20px;
  }
}
.people-quote__list {
  @extend %flickity-fade-wrapper;
  max-width: 1000px;
  margin: 0 auto;
}
.people-quote__item {
  @extend %flickity-fade-item;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.people-quote__content {
  width: 100%;
  @media (min-width: break($_m)) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-left: 70px;
    text-align: left;
  }
  p {
    @media (min-width: break($_m)) {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.people-quote__header {
  order: 1;
  flex: 0 0 auto;
  width: 100%;
  @media (min-width: break($_m)) {
    flex: 0 0 195px;
    order: 0;
  }
}
.people-quote__name {
  @extend .f5;
}
.people-quote__title {
  @extend %caption;
  font-style: italic;
}
.people-quote__name {
  margin-top: 20px;
}
.people-quote__toggles {
  @extend %flickity-as-nav;
  
  position: absolute;
  left: 17px;
  right: 17px;
  bottom: 0;
  transform: translateY(50%);
  display: flex;
  list-style-type: none;
  justify-content: center;
  max-width: 192px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: break($_s)) {
    max-width: none;
  }

  &.flickity-enabled {
    display: block;
  }

  .flickity-viewport {
    @media (min-width: break($_s)) {
      overflow: visible;
    }
  }

  .flickity-prev-next-button {
    &.previous {
      left: -20px;
    }
    &.next {
      right: -20px;
    }
    @media (min-width: break($_m)) {
      display: none;
    }
  }
}
.people-quote__toggle {
  overflow: hidden;
  margin: 0 3px;
  width: 58px;
  height: 58px;
  background: $c-white;
  border: 1px solid transparent;
  border-radius: 50%;
  transition-property: opacity, border-color;
  transition-duration: 0.3s;

  @media (min-width: break($_m)) {
    width: 73px;
    height: 73px;
    margin: 0 15px;
  }

  &.is-nav-selected {
    padding: 3px;
    border-color: $c-blue-border;
    .people-quote__image {
      opacity: 1;
    }
  }
}
.people-quote__image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.3s ease;

  .people-quote__header & {
    display: none;
    margin-bottom: 20px;
    height: auto;
    opacity: 1;
    @media (min-width: break($_m)) {
      display: block;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}