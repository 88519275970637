.search__input-group {
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  border: 1px solid $c-border;
  input[type="text"] {
    border: none;
    outline:0;
    background-color: transparent;
  }
  .header__search & {
    input:first-child {
    width: 99%; //fixes overflow on from submit button
    padding-left: 0;
    }
  }
  &:focus {
    border-color: $c-brown;
  }
  &.is-active {
     border-color: $c-brown;
  }
}

.search__submit {
	background-color: transparent;
  cursor: pointer;
	margin: 0;
 	outline:0;
  border: none;
 	-webkit-appearance: none;
  width: auto;
  padding: .4em .75em;
  .header__search & {
    padding-right: 0;
  }
}

.search-form {
  @extend .site-gutter;
  .header__search & {
    padding: 0;
  }
}