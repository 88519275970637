.block-quote {
  padding: 30px 0 45px;
  @media(min-width:break($_s)) {
    padding: 80px 0;
  }
}

.block-quote__inner {
  @media(min-width:break($_s)){
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
  }
}

.block-quote__content {
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;

  @media(min-width:break($_s)){
    width: 80%;
  }
}

.block-quote__author {
  max-width: 130px;
  flex: 1 0 130px;
  figure {
    @extend .u-bg-cv;
    padding-top: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  &.block-quote__author--left {
    order: -1;
  }
}

.block-quote__signature-name {
  margin-top: 15px;
  text-align: center;
  img {
    margin: 15px 0;
    height: inherit;
    max-width: 100%;
  }
  p {
    line-height: 1.3;
  }
  span {
    @extend .small;
    text-align: center;
    font-style: italic;
  }
}