// COLORS

$c-blue: #0D58D2;
$c-blue-dk: #033EA7;
$c-blue-border: #1158D1;
$c-cream-lt: #FDFAF3;
$c-cream: #F9F4E8;
$c-brown: #482B11;
$c-brown-dk: #1C1712;
$c-brown-lt: #E9E3DD; //pale brown for input borders
$c-white: #fff;
$c-black: #000;
$c-gray: #828282;
$c-gray-lt: #D8D8D8;
$c-theme: $c-blue;
$c-text: $c-brown;
$c-border: $c-brown-lt;
$trans-duration: .3s;

@each $name, $color in
("blue", $c-blue),
("blue-dk", $c-blue-dk),
("cream", $c-cream),
("brown", $c-brown),
("brown-dk", $c-brown-dk),
("brown-lt", $c-brown-lt),
("black", $c-black),
("gray", $c-gray),
("white", $c-white),
("border", $c-border),
{
  .c-#{$name}{
    color: $color;
  }
  .bg-c-#{$name}{
    background-color:$color;
  }
  .c-b-#{$name}{
    border-color:$color;
  }
  .c-b-#{$name}--force{
    border-color:$color !important;
  }
  .c-hover-#{$name}{
    transition: color $trans-duration ease-in-out;
    &:hover{
      color:$color;
    }
  }
  .bg-hover-c-#{$name}{
    transition: background-color $trans-duration ease-in-out;
    &:hover{
      background-color:$color;
    }
  }
}

@mixin c-hover-blue {
  transition: color $trans-duration ease-in-out;
  &:hover{
    color:$c-blue;
  }
}

@mixin c-hover-blue-dk {
  transition: color $trans-duration ease-in-out;
  &:hover{
    color:$c-blue-dk;
  }
}