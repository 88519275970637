%normal-text {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.25px;
}

%large-text {
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.3px;
}

%xlarge-text {
  font-size: 19px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 6px;
  @media (min-width: break($_m)) {
    font-size: 26px;
    line-height: 42px;
    letter-spacing: 9px;
  }
}

.two-columns {
  padding-top: 30px;
  padding-bottom: 45px;
  @media (min-width: break($_m)) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__container {
    max-width: 1060px;
  }

  &__headline {
    text-align: center;
    margin-bottom: 15px;
    @media(min-width: break($_m)) {
      margin-bottom: 25px;
    }
  }
  &__wrapper {
    @media (min-width: break($_m)) {
      display: flex;
    }
  }
  &__column {
    @extend %normal-text;
    p {
      @extend %normal-text;
      &:empty {
        display: none;
      }
    }
    @media (min-width: break($_m)) {
      margin-bottom: 0;

      &:nth-child(odd) {
        width: percentage(4/10);
        padding-right: 20px;
      }
      &:nth-child(even) {
        flex: 1;
        width: percentage(6/10);
      }
    }
    &--large {
      @extend %large-text;
    }
    &--xlarge {
      @extend %xlarge-text;
    }
  }
}

.two-columns__content {
  h2 {
    margin-bottom: 25px;
  }
}
